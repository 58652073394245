import React, {Component} from 'react';
import './style.less';
import {Table, message, Pagination, Button, Input, Select} from "antd";
import {getRequest} from "../../../../common/util/request";
import {tc} from "../../../../common/util/number";
import {formatYuan} from "../../../../common/util/number";
import MultipleModal from "./multipleModal";
import {emitter, EVENTS} from "../../../../common/util/event";
import {MenuKey} from "../../../../common/constant/common";
import {SearchOutlined} from "@ant-design/icons";

class GameResultList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            game_id: 1,
            page: 1,
            limit: 15,
            list: [],
            total: 0,
            game_no: '',
            multipleModal: null, // 弹窗-赔率
        };
        this.getGameResultList = this.getGameResultList.bind(this);
        this.changePage = this.changePage.bind(this);
        this.viewMultiple = this.viewMultiple.bind(this);
        this.pageLoaded = this.pageLoaded.bind(this);
        this.changeGameNo = this.changeGameNo.bind(this);
        this.search = this.search.bind(this);
        this.changeGame = this.changeGame.bind(this);
    }
    componentDidMount() {
        emitter.addListener(MenuKey.GameResultList.id, this.pageLoaded);
    }
    componentWillUnmount() {
        emitter.removeListener(MenuKey.GameResultList.id, this.pageLoaded);
    }
    // 页面加载
    pageLoaded(payload){
        this.getGameResultList();
    }
    // 获取列表
    getGameResultList(){
        const query = {
            game_id: this.state.game_id,
            page: this.state.page,
            page_size: this.state.limit,
            game_no: this.state.game_no !== '' ? parseInt(this.state.game_no) : 0,
        };
        getRequest("/admin/game/result/list", query, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                this.setState({list: data.data.list, total: data.data.total});
            }
        })
    }
    // 更换页码
    changePage(page, pageSize){
        this.setState({limit: pageSize, page: page}, ()=>{
            this.getGameResultList();
        });
    }
    // 查看赔率
    viewMultiple(id, str){
        this.setState({multipleModal: {id: id, multiple: str}})
    }
    // 查看投注记录
    viewBetRecord(data){
        const payload = {
            game_no: data.id,
        };
        emitter.emit(EVENTS.SHOW_MENU, MenuKey.GameBetRecord.id, payload);
    }
    // 修改期号
    changeGameNo(e){
        const v = e.target.value;
        this.setState({game_no: v});
    }
    // 修改游戏
    changeGame(v){
        this.setState({game_id: v});
    }
    // 搜索
    search(){
        this.setState({page: 1}, ()=>{
            this.getGameResultList();
        })
    }
    render() {
        const columns = [
            {
                title: '期号',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: '开奖时间',
                dataIndex: 'open_time',
                key: 'open_time',
            },
            {
                title: '开奖结果',
                dataIndex: 'open_numbers',
                key: 'open_numbers',
                render: (text,d)=>{
                    if(!d.status){
                        return (
                            <span>
                                <span className="number-ball">?</span>+
                                <span className="number-ball">?</span>+
                                <span className="number-ball">?</span>=
                                <span className="number-ball">?</span>
                            </span>
                        );
                    }else{
                        return (
                            <span>
                                <span className="number-ball">{text[0]}</span>+
                                <span className="number-ball">{text[1]}</span>+
                                <span className="number-ball">{text[2]}</span>=
                                <span className="number-ball">{tc(text[3])}</span>
                            </span>
                        )
                    }
                }
            },
            {
                title: '中奖人数/投注人数',
                dataIndex: 'real_bet_user_count',
                key: 'real_bet_user_count',
                render: (text,d)=>{
                    return <span><span className={d.real_win_user_count > 0 ? "win-user-count" : ""}>{d.real_win_user_count}</span> / {d.real_bet_user_count}</span>
                }
            },
            {
                title: '投注总金额(元)',
                dataIndex: 'real_bet_amount',
                key: 'real_bet_amount',
                render: (text,d)=>{
                    return <span>{formatYuan(text)}</span>
                }
            },
            {
                title: '用户输赢(元)',
                dataIndex: 'winlose',
                key: 'winlose',
                render: (text,d)=>{
                    const amount = d.winlose-d.real_bet_amount;
                    return <span className={amount > 0 ? "number-lose" : amount === 0 ? "" : "number-win"}>{formatYuan(amount)}</span>
                }
            },
            {
                title: '查看',
                dataIndex: 'view',
                key: 'view',
                render: (text,d)=>{
                    return (
                        <div className="btn-block">
                            <Button size="small" className="btn-info" onClick={()=>this.viewMultiple(d.id, d.multiple)}>开奖赔率</Button>
                            <Button size="small" className="btn-warning" onClick={()=>this.viewBetRecord(d)}>投注记录</Button>
                        </div>
                    )
                }
            },
        ];
        return (
            <div className="game-result-list-panel">
                <div className="filter-block">
                    <Select className="input-select-type" value={this.state.game_id} onChange={this.changeGame}>
                        <Select.Option value={1}>加拿大28</Select.Option>
                        <Select.Option value={2}>台湾28</Select.Option>
                    </Select>
                    {/*<label className="label">期号: </label>*/}
                    <Input className="input-game-no" placeholder="游戏期号" value={this.state.game_no} onChange={this.changeGameNo}/>
                    <Button type="primary" icon={<SearchOutlined />} onClick={this.search}>查询</Button>
                </div>
                <Table bordered={true} rowKey="id" size="small" dataSource={this.state.list} columns={columns} pagination={false} className="game-result-table"/>
                <Pagination
                    size="small"
                    current={this.state.page}
                    total={this.state.total}
                    pageSize={this.state.limit}
                    onChange={this.changePage}
                    showSizeChanger={false}
                    showTotal={(total) => `共 ${total} 条数据`}
                />
                {
                    this.state.multipleModal === null ? null :
                        <MultipleModal data={this.state.multipleModal} onCancel={()=>this.setState({multipleModal: null})}/>
                }
            </div>
        );
    }
}

export default GameResultList;

