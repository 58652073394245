import React, {Component} from 'react';
import {Switch, Card, Tag, Avatar, message, Modal, Radio} from "antd";
import dayjs from "dayjs";
import {emitter, EVENTS} from "../../../../common/util/event";
import {postRequest} from "../../../../common/util/request";
import {formatSecond, formatYuan} from "../../../../common/util/number";

class GameRound extends Component {
    constructor(props) {
        super(props);
        this.state = {
            group: {
                id: "",
                name: "",
                cover: "",
            }, // 群组信息
            game_id: 1, // 游戏类型
            open_status: false, // 开启状态
            game_no: 0, // 游戏期数
            start_at: 0, // 开始运行时间
            current_time: 0, // 服务器时间
            bet_stats: {
                bet_user_count: 0, // 下注人数
                bet_amount: 0, // 下注总额
                exceed_count: 0, // 超额人数
                invalid_count: 0, // 无效人数
                timeout_count: 0, // 超时人数
            }
        };
        this.updateRoundInfo = this.updateRoundInfo.bind(this);
        this.updateCurrentTime = this.updateCurrentTime.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.switchGame = this.switchGame.bind(this);
    }
    componentDidMount() {
        emitter.addListener(EVENTS.ROUND_INFO, this.updateRoundInfo);
        this.timer = setInterval(this.updateCurrentTime, 1000);
    }
    componentWillUnmount() {
        emitter.removeListener(EVENTS.ROUND_INFO, this.updateRoundInfo);
        if(this.timer !== null){
            clearInterval(this.updateCurrentTime);
            this.updateCurrentTime = null;
        }
    }
    // 更新时间
    updateCurrentTime(){
        this.setState({current_time: this.state.current_time === 0 ? 0 : this.state.current_time+1});
    }
    // 更新
    updateRoundInfo(data){
        this.setState({
            game_id: data.game_id,
            group: data.group,
            open_status: data.open_status,
            game_no: data.game_no,
            start_at: data.start_at,
            current_time: data.current_time,
            bet_stats: data.stats,
        });
    }
    // 更新游戏状态
    changeStatus(v){
        Modal.confirm({
            title: v ? '开启游戏' : '关闭游戏',
            content: v ? '确定要开启游戏吗? 开启游戏后, 群内将取消禁言, 并开始接收用户投注' : '确定要关闭游戏吗? 关闭游戏后, 所有未结算的下注单将被取消, 积分退还到玩家余额',
            onOk: ()=>{
                this.setState({open_status: v, start_at: !v ? 0 : this.state.start_at}, ()=>{
                    postRequest("/admin/game/status/update",{open: v}, (data)=>{
                        if(data.code !== 0){
                            message.error(data.msg);
                        }else{
                            message.success(`${v ? "开启" : "关闭"}游戏成功`);
                        }
                    })
                });
            }
        });
    }
    // 切换游戏
    switchGame(e){
        if(this.state.open_status){
            message.warning("请先停止游戏后, 再进行游戏切换");
            return;
        }
        const v = e.target.value;
        this.props.parent.switchGame(v);
    }
    render() {
        return (
            <Card className="game-round-card">
                <Tag className="tag-label" color="#f44336">当前回合</Tag>
                <div className="line-block">
                    <label>当前游戏: </label>
                    <span className="value">
                        <Radio.Group onChange={this.switchGame} value={this.state.game_id}>
                            <Radio value={1}>加拿大28</Radio>
                            <Radio value={2}>台湾28</Radio>
                        </Radio.Group>
                    </span>
                </div>
                <div className="line-block">
                    <label>游戏状态: </label>
                    <span className="value">
                        <Switch checkedChildren="开启" unCheckedChildren="关闭" checked={this.state.open_status} onChange={this.changeStatus}/>
                    </span>
                </div>
                <div className="line-block">
                    <label>系统时间: </label>
                    <span className="value">{this.state.current_time === 0 ? "-" : dayjs.unix(this.state.current_time).format('HH:mm:ss')}</span>
                </div>
                <div className="line-block">
                    <label>运行时间: </label>
                    <span className="value">{this.state.start_at === 0 ? "-" : formatSecond(this.state.current_time-this.state.start_at)}</span>
                </div>
                <div className="line-block">
                    <label>当前群组: </label>
                    <span className="value">
                        {
                            this.state.group.id === '' ? '-' :
                            <>
                                <Avatar src={this.state.group.cover} size={30} className="group-cover"/>
                                {this.state.group.name}
                            </>
                        }
                    </span>
                </div>
                <div className="line-block">
                    <label>当前期数: </label>
                    <span className="value">{this.state.game_no === 0 ? "-" : this.state.game_no}</span>
                </div>
                <div className="line-block info">
                    <label>玩家注数: </label>
                    <span className="value">{this.state.bet_stats.bet_user_count}</span>
                </div>
                <div className="line-block info">
                    <label>累计下注: </label>
                    <span className="value">{formatYuan(this.state.bet_stats.bet_amount)}</span>
                </div>
                <div className="line-block warning">
                    <label>超额人数: </label>
                    <span className="value">{this.state.bet_stats.exceed_count}</span>
                </div>
                <div className="line-block warning">
                    <label>无效人数: </label>
                    <span className="value">{this.state.bet_stats.invalid_count}</span>
                </div>
                <div className="line-block info-two">
                    <label>超时人数: </label>
                    <span className="value">{this.state.bet_stats.timeout_count}</span>
                </div>
            </Card>
        );
    }
}

export default GameRound;

