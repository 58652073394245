import React, {Component} from 'react';
import './style.less';
import {Button, message, Pagination, Popover, Table, Tag} from "antd";
import {getRequest} from "../../../../common/util/request";
import {emitter} from "../../../../common/util/event";
import {MenuKey} from "../../../../common/constant/common";
import UserInfo from "../../../../common/component/userInfo";
import {PlusOutlined} from "@ant-design/icons";
import AddModal from "./addModal";

class BotList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            limit: 15,
            list: [],
            total: 0,
            showAddModal: false,
        };
        this.changePage = this.changePage.bind(this);
        this.getBotList = this.getBotList.bind(this);
        this.pageLoaded = this.pageLoaded.bind(this);
    }
    componentDidMount() {
        emitter.addListener(MenuKey.BotList.id, this.pageLoaded);
    }
    componentWillUnmount() {
        emitter.removeListener(MenuKey.BotList.id, this.pageLoaded);
    }
    // 页面加载
    pageLoaded(payload){
        this.getBotList();
    }
    // 获取列表
    getBotList(){
        const query = {
            page: this.state.page,
            page_size: this.state.limit,
        };
        getRequest("/admin/user/bot/list", query, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                this.setState({list: data.data.list, total: data.data.total});
            }
        })
    }
    // 更换页码
    changePage(page, pageSize){
        this.setState({limit: pageSize, page: page}, ()=>{
            this.getBotList();
        });
    }
    render() {
        const columns = [
            {
                title: '用户ID',
                dataIndex: 'id',
                key: 'id',
                render: (text,d)=>{
                    return d.user.user_id
                }
            },
            {
                title: '昵称',
                dataIndex: 'user',
                key: 'user',
                render: (text,d)=>{
                    return (
                        <UserInfo data={text} />
                    )
                }
            },
            {
                title: '类型',
                dataIndex: 'type',
                key: 'type',
                render: (text,d)=>{
                    if(d.user.type === 1){
                        return (
                            <Tag color="#8bc34a">机器人</Tag>
                        )
                    }else{
                        return (
                            <Tag color="#607d8b">游戏托</Tag>
                        )
                    }
                }
            },
            {
                title: '账号',
                dataIndex: 'account',
                key: 'account',
            },
            {
                title: '密码',
                dataIndex: 'password',
                key: 'password',
                render: (text,d)=>{
                    let str = "*".repeat(text.length);
                    return (
                        <Popover content={text}>
                            <span>{str}</span>
                        </Popover>
                    )
                }
            },
        ];
        return (
            <div className="bot-list-panel">
                <div className="filter-block">
                    <Button type="primary" className="btn-success" icon={<PlusOutlined />} onClick={()=>{this.setState({showAddModal: true})}}>添加机器人</Button>
                </div>
                <Table bordered={true} rowKey={(record) => record.user.user_id} size="small" dataSource={this.state.list} columns={columns} pagination={false} className="bot-list-table"/>
                <Pagination
                    size="small"
                    current={this.state.page}
                    total={this.state.total}
                    pageSize={this.state.limit}
                    onChange={this.changePage}
                    showSizeChanger={false}
                    showTotal={(total) => `共 ${total} 条数据`}
                />
                {
                    !this.state.showAddModal ? null :
                        <AddModal onCancel={()=>this.setState({showAddModal: false})} onOk={()=>{this.setState({showAddModal: false});this.getBotList();}}/>
                }
            </div>
        );
    }
}

export default BotList;

