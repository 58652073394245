import React, {Component} from 'react';
import {PlusOutlined, MinusOutlined} from '@ant-design/icons';
import {InputNumber} from "antd";

class RebateInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: this.props.index,
            amount: this.props.data.amount,
            percent: this.props.data.percent,
        };
        this.changeAmount = this.changeAmount.bind(this);
        this.changePercent = this.changePercent.bind(this);
    }
    componentDidMount() {
    }
    // 修改金额
    changeAmount(v){
        this.setState({amount: v},()=>{
            this.props.onChange("amount", v);
        });
    }
    // 修改比例
    changePercent(v){
        this.setState({percent: v},()=>{
            this.props.onChange("percent", v);
        });
    }
    render() {
        return (
            <div className="rebate-input">
                亏损达到
                <InputNumber controls={false} value={this.state.amount} size="small" onBlur={this.props.onBlur} onChange={this.changeAmount} min={0} max={99999999}/>
                元, 返利
                <InputNumber controls={false} value={this.state.percent} size="small" onBlur={this.props.onBlur} onChange={this.changePercent}  min={0} max={100}/>
                %
                {
                    this.state.index === 0 ?
                        <PlusOutlined className="icon-extra" onClick={this.props.addAction}/> :
                        <MinusOutlined className="icon-extra" onClick={()=>this.props.removeAction(this.state.index)}/>
                }
            </div>
        );
    }
}

export default RebateInput;

