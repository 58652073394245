import React, {Component} from 'react';
import './style.less';
import {Avatar, Button, message, Table, Tag, Modal} from "antd";
import {getRequest, postRequest} from "../../../../common/util/request";
import {emitter} from "../../../../common/util/event";
import {MenuKey} from "../../../../common/constant/common";

class GroupList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
        };
        this.getGroupList = this.getGroupList.bind(this);
        this.pageLoaded = this.pageLoaded.bind(this);
        this.setGameGroup = this.setGameGroup.bind(this);
    }
    componentDidMount() {
        emitter.addListener(MenuKey.GroupList.id, this.pageLoaded);
    }
    componentWillUnmount() {
        emitter.removeListener(MenuKey.GroupList.id, this.pageLoaded);
    }
    // 页面加载
    pageLoaded(payload){
        this.getGroupList();
    }
    // 获取列表
    getGroupList(){
        const query = {};
        getRequest("/admin/user/group/list", query, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                this.setState({list: data.data});
            }
        })
    }
    // 设置游戏群
    setGameGroup(id){
        Modal.confirm({
            title: "设置游戏群",
            content: "确定要将此群设置为游戏群吗？请确保游戏程序已经停止后再进行设置",
            onOk: ()=>{
                postRequest("/admin/user/game/group/set", {group_id: id}, (data)=>{
                    if(data.code !== 0){
                        message.error(data.msg);
                    }else{
                        message.success("设置成功");
                        this.getGroupList();
                    }
                })
            }
        })
    }
    // 同步群信息
    syncGroup(id){
        postRequest("/admin/user/group/sync", {group_id: id}, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                message.success("操作成功, 请稍后刷新列表确认");
            }
        })
    }
    render() {
        const columns = [
            {
                title: '群ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: '群名称',
                dataIndex: 'name',
                key: 'name',
                render: (text,d)=>{
                    return (
                        <div className="user-info">
                            <Avatar src={d.cover} size={30}/>
                            <span className="nickname">{d.name}</span>
                        </div>
                    )
                }
            },
            {
                title: '成员数',
                dataIndex: 'member_count',
                key: 'member_count',
            },
            {
                title: '游戏群',
                dataIndex: 'is_game_group',
                key: 'is_game_group',
                render: (text,d)=>{
                    if(text){
                        return <Tag color="#4caf50">是</Tag>
                    }else{
                        return <Tag color="#9e9e9e">否</Tag>
                    }
                }
            },
            {
                title: '操作',
                dataIndex: 'extra',
                key: 'extra',
                render: (text,d)=>{
                    return (
                        <div className="btn-block">
                            {
                                d.is_game_group ? null :
                                    <Button size="small" className="btn-info" onClick={()=>this.setGameGroup(d.id)}>设置游戏群</Button>
                            }
                            <Button size="small" className="btn-warning" onClick={()=>this.syncGroup(d.id)}>同步群信息</Button>
                        </div>
                    )
                }
            },
        ];
        return (
            <div className="group-list-panel">
                <Table bordered={true} rowKey="id" size="small" dataSource={this.state.list} columns={columns} pagination={false} className="group-list-table"/>
            </div>
        );
    }
}

export default GroupList;

