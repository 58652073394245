import {Component} from 'react';
import './style.less';
import {Col, message, Row} from "antd";
import GamePlayer from "./player";
import GameStatus from "./status";
import GameRound from "./round";
import {getRequest, postRequest} from "../../../../common/util/request";
import {emitter, EVENTS} from "../../../../common/util/event";
import GameHistory from "./history";
import PaymentList from "./payment";
import Distribution from "./distribution";

class GameCurrent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.switchGame = this.switchGame.bind(this);
    }
    componentDidMount() {
        this.getRoundInfo();
        // 定时刷新回合状态
        this.roundTimer = setInterval(this.getRoundInfo, 3000);
    }
    componentWillUnmount() {
        if(this.roundTimer !== null){
            clearInterval(this.roundTimer);
            this.roundTimer = null;
        }
    }
    // 获取回合信息
    getRoundInfo(){
        getRequest("/admin/game/round/current", {}, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                emitter.emit(EVENTS.ROUND_INFO, data.data);
            }
        });
    }
    // 切换游戏
    switchGame(gameId){
        postRequest("/admin/game/switch", {game_id: gameId}, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                message.success("切换游戏成功");
                // 重新加载数据
                this.getRoundInfo();
            }
        })
    }
    render() {
        return (
            <Row className="game-current-panel">
                <Col span={6}>
                    <GameRound parent={this}/>
                    <GameStatus />
                </Col>
                <Col span={12}>
                    <GamePlayer />
                </Col>
                <Col span={6}>
                    {/*<Distribution />*/}
                    <PaymentList />
                </Col>
                <Col span={24}>
                    <GameHistory />
                </Col>
            </Row>
        );
    }
}

export default GameCurrent;

