import React, {Component} from 'react';
import { withRouter } from "react-router-dom";
import {Row, Col, Avatar, Modal, Dropdown} from 'antd';
import { UserOutlined, PoweroffOutlined } from '@ant-design/icons';
import './style.less';
import {clearToken} from "../../../common/util/config";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.logout = this.logout.bind(this);
    }
    componentDidMount() {
    }
    componentWillUnmount(){
    }
    // 退出登录
    logout(){
        Modal.confirm({
            title: "安全退出",
            content: "确定要退出登录状态吗?",
            okText: "确定",
            cancelText: "取消",
            onOk: ()=>{
                clearToken();
                this.props.history.push("/login");
            }
        })
    }
    render() {
        const items = [
            {
                label: (
                    <><PoweroffOutlined />安全退出</>
                ),
                key: '3',
                onClick: this.logout,
            },
        ];
        return (
            <Row className="header">
                <Col span={12} className="left">
                    <h3 className="app-name">AA国际群28管理系统</h3>
                </Col>
                <Col span={12} className="right">
                    <Dropdown menu={{ items }} trigger="click" overlayClassName="user-dropdown-menu" placement="bottomRight" arrow={true}>
                        <div className="user-info">
                            <Avatar className="avatar" style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                            <span className="nickname">管理员</span>
                        </div>
                    </Dropdown>
                </Col>
            </Row>
        );
    }
}

export default withRouter(Header);

