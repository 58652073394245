import React, {Component} from 'react';
import { message } from 'antd';
import kodinger from '../../static/images/kodinger.jpeg';
import {CSSTransition} from 'react-transition-group';
import {checkEmail, checkPassword} from "../../common/util/validate";
import {postRequest} from "../../common/util/request";
import {saveToken} from "../../common/util/config";
import './style.less';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style: 1, // 1-登陆
        };
        this.switchStyle = this.switchStyle.bind(this);
        this.login = this.login.bind(this);
        this.register = this.register.bind(this);
        this.loginEmail = React.createRef();
        this.loginPassword = React.createRef();
        this.registerEmail = React.createRef();
        this.registerPassword = React.createRef();
        this.loginCode = React.createRef();
    }
    componentDidMount() {
    }
    // 切换登陆/注册
    switchStyle(e, mode){
        e.preventDefault();
        e.stopPropagation();
        this.setState({style: mode});
    }
    // 登陆
    login(e){
        e.preventDefault();
        e.stopPropagation();
        const email = this.loginEmail.current.value;
        const password = this.loginPassword.current.value;
        const code = this.loginCode.current.value;
        if(email === ''){
            message.warning("账号格式不正确");
            return;
        }
        if(!checkPassword(password)){
            message.warning("密码格式不正确, 密码长度应在6至16个字符之间");
            return;
        }
        if(code.length !== 6){
            message.warning("Google验证码不正确");
            return;
        }
        postRequest("/admin/passport/login", {username: email, password: password, code: code}, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                // 保存token
                saveToken(data.data.token);
                // 跳转主页
                this.props.history.push("/");
            }
        });
    }
    // 注册
    register(e){
        e.preventDefault();
        e.stopPropagation();
        const email = this.registerEmail.current.value;
        const password = this.registerPassword.current.value;
        if(email === '' || !checkEmail(email)){
            message.warning("邮箱格式不正确");
            return;
        }
        if(!checkPassword(password)){
            message.warning("密码格式不正确, 密码长度应在6至16个字符之间");
            return;
        }
        postRequest("/passport/register", {email: email, password: password}, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                message.success("注册成功, 请登陆");
                this.setState({style: 1});
            }
        });
    }
    // 禁止自动填充
    forbidAutoComplete(e){
        e.target.setAttribute('autocomplete', 'off');
    }
    render() {
        return (
            <div className="lowin">
                <div className="lowin-brand">
                    <img src={kodinger} alt="logo" />
                </div>
                <div className="lowin-wrapper">
                    <CSSTransition
                        in={this.state.style === 1}
                        timeout={1000}
                        classNames="login-animated"
                        unmountOnExit
                    >
                        <div className="lowin-box lowin-login">
                            <div className="lowin-box-inner">
                                <form>
                                    <p className="title">AA国际群28管理系统</p>
                                    <p>账号登陆</p>
                                    <div className="lowin-group">
                                        <label>账号</label>
                                        <input onFocus={this.forbidAutoComplete} type="email" name="email" className="lowin-input" ref={this.loginEmail}/>
                                    </div>
                                    <div className="lowin-group password-group">
                                        <label>密码</label>
                                        <input onFocus={this.forbidAutoComplete} type="password" name="password" className="lowin-input" ref={this.loginPassword}/>
                                    </div>
                                    <div className="lowin-group">
                                        <label>Google验证码</label>
                                        <input onFocus={this.forbidAutoComplete} name="code" className="lowin-input" ref={this.loginCode}/>
                                    </div>
                                    <button className="lowin-btn login-btn" onClick={this.login}>
                                        登陆
                                    </button>
                                    {/*<div className="text-foot">*/}
                                    {/*    没有账号? <a href="#" className="register-link" onClick={(e)=>{this.switchStyle(e, 2)}}>点击注册</a>*/}
                                    {/*</div>*/}
                                </form>
                            </div>
                        </div>
                    </CSSTransition>

                    <CSSTransition
                        in={this.state.style === 2}
                        timeout={5000}
                        classNames="register-animated"
                        unmountOnExit
                    >
                        <div className="lowin-box lowin-register">
                            <div className="lowin-box-inner">
                                <form>
                                    <p className="title">ScumGame</p>
                                    <p>让我们创建账号吧</p>
                                    <div className="lowin-group">
                                        <label>邮箱</label>
                                        <input onFocus={this.forbidAutoComplete} type="email" name="email" className="lowin-input" ref={this.registerEmail}/>
                                    </div>
                                    <div className="lowin-group">
                                        <label>密码</label>
                                        <input onFocus={this.forbidAutoComplete} type="password" name="password" className="lowin-input" ref={this.registerPassword}/>
                                    </div>
                                    <button className="lowin-btn" onClick={this.register}>
                                        注册
                                    </button>
                                    <div className="text-foot">
                                        已经有账号了? <a href="#" className="login-link" onClick={(e)=>{this.switchStyle(e, 1)}}>点击登陆</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </CSSTransition>
                </div>
            </div>
        );
    }
}

export default Login;

