import React, {Component} from 'react';
import {Checkbox, Col, Divider, InputNumber, Radio, Tag} from "antd";

class BetLimit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            min: 30, // 单期最小投注
            max: 50000, // 单期最大投注
            number_limit: false, // 是否开启数字个数限制
            number_limit_count: 4, // 单期单点数字个数限制
            wage_type: 1, // 流水规则
        };
    }
    componentDidMount() {
    }
    componentWillReceiveProps(nextProps, nextContext) {
        this.setState(nextProps.data);
    }
    // 更新指令
    updateAttr(v,attr){
        this.props.onUpdate(v,"bet_limit",attr);
    }
    // 获取值
    getValue(){
        return this.state;
    }
    render() {
        return (
            <Col span={8} className="rule-block">
                <Divider plain orientation="left"><Tag color="#ff5722">投注限制</Tag></Divider>
                <div className="input-box">
                    <label className="label">单期限额: </label>
                    <div className="form-box">
                        <InputNumber size="small" value={this.state.min} controls={false} onChange={(v)=>this.updateAttr(v, "min")}/>~
                        <InputNumber size="small" value={this.state.max} controls={false} onChange={(v)=>this.updateAttr(v, "max")}/>元
                    </div>
                </div>
                <div className="input-box">
                    <label className="label">流水规则: </label>
                    <div className="form-box">
                        <Radio.Group onChange={(e)=>this.updateAttr(e.target.value, "wage_type")} value={this.state.wage_type}>
                            <Radio value={1}>默认</Radio>
                            <Radio value={2}>单门</Radio>
                            <Radio value={3}>全无</Radio>
                        </Radio.Group>
                    </div>
                </div>
                <div className="input-box">
                    <label className="label">限制条件: </label>
                    <div className="form-box">
                        <Checkbox checked={this.state.number_limit} onChange={(e)=>this.updateAttr(e.target.checked, "number_limit")}>
                            单点数字, 单期不同点数最多<InputNumber size="small" min={1} max={28} value={this.state.number_limit_count} controls={false} onChange={(v)=>this.updateAttr(v, "number_limit_count")}/>个
                        </Checkbox>
                    </div>
                </div>
            </Col>
        );
    }
}

export default BetLimit;

