import React,{Component} from 'react';
import {Alert, Input, InputNumber, message, Modal} from "antd";
import {postRequest} from "../../../../common/util/request";

class AdminModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: this.props.data.user_id, // 用户ID
        };
        this.doPayment = this.doPayment.bind(this);
        this.amountRef = React.createRef();
        this.descriptionRef = React.createRef();
    }
    componentDidMount() {
    }
    doPayment(){
        const val = parseInt(this.amountRef.current.value);
        const desc = this.descriptionRef.current.input.value;
        if(desc === ""){
            message.warning("请输入操作原因");
            return;
        }
        postRequest("/admin/user/points/add", {user_id: this.state.user_id, points: val, description: desc}, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                message.success("操作成功");
                this.props.onCancel();
                this.props.onOk();
            }
        })
    }
    render() {
        return (
            <Modal
                className="payment-modal"
                open={true}
                onCancel={this.props.onCancel}
                title="管理员积分操作"
                maskClosable={false}
                width={400}
                onOk={this.doPayment}
            >
                <Alert message="正数为增加金额, 负数为扣除金额" type="info" showIcon />
                <div className="input-box">
                    <label className="label">金额:</label>
                    <InputNumber ref={this.amountRef} defaultValue={0} min={-999999} max={999999}/>
                </div>
                <div className="input-box">
                    <label className="label">备注:</label>
                    <Input ref={this.descriptionRef} placeholder="请输入此次操作的原因"/>
                </div>
            </Modal>
        );
    }
}

export default AdminModal;

