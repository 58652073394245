import {Component} from 'react';
import { withRouter } from "react-router-dom";
import {CheckLogin} from "../../common/util/config";
import './style.less';
import Header from "./header";
import Menu from "./menu";
import Content from "./content";
import RingBell from "./ringbell";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {
        // 校验是否登录
        if(!CheckLogin()){
            this.props.history.push("/login");
        }
    }
    componentWillUnmount(){
    }
    render() {
        return (
            <div id="main-panel">
                <Header />
                <Menu />
                <Content />
                <RingBell />
            </div>
        );
    }
}

export default withRouter(Home);

