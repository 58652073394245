import React, {Component} from 'react';
import {Input} from "antd";
import {CloseOutlined} from '@ant-design/icons';

class AutoReplyComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.data.id,
            content: this.props.data.content,
            reply: this.props.data.reply,
        };
        this.onBlur = this.onBlur.bind(this);
    }
    componentDidMount() {
    }
    onBlur(){
        this.props.onUpdate(this.state.id,this.state.content,this.state.reply);
    }
    render() {
        return (
            <div className="input-box">
                <Input value={this.state.content} onBlur={this.onBlur} onChange={(e)=>this.setState({content: e.target.value})} placeholder="群里有人说" />
                <Input value={this.state.reply} onBlur={this.onBlur} onChange={(e)=>this.setState({reply: e.target.value})} placeholder="自动回复" />
                <CloseOutlined className="remove-icon" onClick={()=>this.props.onRemove(this.state.id)}/>
            </div>
        );
    }
}

export default AutoReplyComponent;

