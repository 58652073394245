import React, {Component} from 'react';
import './style.less';
import {getRequest} from "../../common/util/request";
import {message} from "antd";

class BetRecord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            game_no: 0,
            list: [],
            loading: true,
        };
        this.getBetRecord = this.getBetRecord.bind(this);
    }
    componentDidMount() {
        this.getBetRecord();
    }
    // 获取投注记录
    getBetRecord(){
        getRequest("/admin/game/current/bet/record", {}, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                this.setState({game_no: data.data.game_no, list: data.data.list, loading: false});
            }
        })
    }
    render() {
        return (
            <div id="bet-table" className="game-bet-record">
                <div className="header">
                    水云天加拿大第{this.state.game_no}期游戏投注记录
                </div>
                <table className="bet-table">
                    <tbody>
                        <tr className="table-header">
                            <td>序号</td>
                            <td>用户</td>
                            <td>投注金额</td>
                            <td>投注内容</td>
                        </tr>
                        {
                            this.state.list.map((item, i)=>{
                                return (
                                    <tr key={i}>
                                        <td>{i+1}</td>
                                        <td>{item.user.nickname}</td>
                                        <td>{item.bet_amount}</td>
                                        <td>{item.bet_str}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {
                    this.state.loading ? null :
                        <div id="over"></div>
                }
            </div>
        );
    }
}

export default BetRecord;

