const EventEmitter = require("events");

export const emitter = new EventEmitter();

// 全局事件
export const EVENTS = {
    SHOW_MENU: "show_menu", // 显示菜单
    ROUND_INFO: "round_info", // 回合信息
    RING_BELL: "ring_bell", // 声音提示
};
