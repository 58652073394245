import React, {Component} from 'react';
import {Col, Divider, InputNumber, Table, Tag} from "antd";
import {BetNum, getBetNumName} from "../../../../common/constant/game28";
import MultipleExtra from "./multipleExtra";

class Multiple extends Component {
    constructor(props) {
        super(props);
        this.state = {
            multiples: [
                {bet_num: BetNum.BetNew28Num0, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num1, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num2, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num3, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num4, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num5, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num6, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num7, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num8, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num9, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num10, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num11, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num12, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num13, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num14, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num15, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num16, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num17, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num18, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num19, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num20, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num21, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num22, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num23, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num24, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num25, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num26, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28Num27, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28NumSingle, multiple: 1, multiple_extra: [{bet_amount:0,multiple:1},null], bet_max: 10000},
                {bet_num: BetNum.BetNew28NumBig, multiple: 1, multiple_extra: [{bet_amount:0,multiple:1},null], bet_max: 10000},
                {bet_num: BetNum.BetNew28NumSmallSingle, multiple: 1, multiple_extra: [{bet_amount:0,multiple:1},null], bet_max: 10000},
                {bet_num: BetNum.BetNew28NumBigSingle, multiple: 1, multiple_extra: [{bet_amount:0,multiple:1},null], bet_max: 10000},
                {bet_num: BetNum.BetNew28NumSmallPeek, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28NumDouble, multiple: 1, multiple_extra: [{bet_amount:0,multiple:1},null], bet_max: 10000},
                {bet_num: BetNum.BetNew28NumSmall, multiple: 1, multiple_extra: [{bet_amount:0,multiple:1},null], bet_max: 10000},
                {bet_num: BetNum.BetNew28NumSmallDouble, multiple: 1, multiple_extra: [{bet_amount:0,multiple:1},null], bet_max: 10000},
                {bet_num: BetNum.BetNew28NumBigDouble, multiple: 1, multiple_extra: [{bet_amount:0,multiple:1},null], bet_max: 10000},
                {bet_num: BetNum.BetNew28NumBigPeek, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28NumDragon, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28NumTiger, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28NumLeopard, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28TypeLeopard, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28TypeSequence, multiple: 1, multiple_extra: null, bet_max: 10000},
                {bet_num: BetNum.BetNew28TypePair, multiple: 1, multiple_extra: null, bet_max: 10000},
            ], // 赔率
        };
        this.getMultipleCombinationData = this.getMultipleCombinationData.bind(this);
        this.getMultipleObject = this.getMultipleObject.bind(this);
        this.getMultipleComponent = this.getMultipleComponent.bind(this);
        this.getMultipleExtraComponent = this.getMultipleExtraComponent.bind(this);
        this.getBetMaxComponent = this.getBetMaxComponent.bind(this);
        this.changeObjectExtraAttr = this.changeObjectExtraAttr.bind(this);
        this.changeObjectAttr = this.changeObjectAttr.bind(this);
        this.initData = this.initData.bind(this);
    }
    componentDidMount() {
    }
    // 初始化
    initData(list){
        this.setState({multiples: list});
    }
    // 获取值
    getValue(){
        return this.state.multiples;
    }
    // 获取赔率组合数据
    getMultipleCombinationData(betNum1, betNum2){
        const betObj1 = this.getMultipleObject(betNum1);
        const betObj2 = this.getMultipleObject(betNum2);
        return {
            id: betNum1,
            name: getBetNumName(betNum1),
            max: betObj1.bet_max,
            multiple:betObj1.multiple,
            multiple_extra: betObj1.multiple_extra,
            id2: betNum2,
            name2: getBetNumName(betNum2),
            max2: betObj2.bet_max,
            multiple2:betObj2.multiple,
            multiple2_extra: betObj2.multiple_extra
        };
    }
    // 获取赔率对象
    getMultipleObject(betNum){
        for(let i=0;i<this.state.multiples.length;i++){
            if(this.state.multiples[i].bet_num === betNum){
                return this.state.multiples[i];
            }
        }
        return {};
    }
    // 获取投注名组件
    getBetNameComponent(text,d,id){
        if(id <= BetNum.BetNew28Num27){
            return (
                <span className="number-ball">{text}</span>
            )
        }else{
            return <Tag className="bet-label" color="#00bcd4">{text}</Tag>;
        }
    }
    // 获取赔率组件
    getMultipleComponent(text,d,id){
        return (
            <InputNumber size="small" value={text} controls={false} onChange={(v)=>this.changeObjectAttr(id, v, "multiple")}/>
        )
    }
    // 获取额外赔率组件
    getMultipleExtraComponent(text,d,id){
        if(id <= BetNum.BetNew28Num27 || id>=BetNum.BetNew28NumDragon || id === BetNum.BetNew28NumBigPeek || id === BetNum.BetNew28NumSmallPeek){
            return "-"
        }else{
            return (
                <>
                    <MultipleExtra data={text[0]} onChange={(v)=>this.changeObjectExtraAttr(id, v, "multiple_extra", 0)}/>
                    <MultipleExtra data={text[1]} onChange={(v)=>this.changeObjectExtraAttr(id, v, "multiple_extra", 1)}/>
                </>
            )
        }
    }
    // 获取最大投注组件
    getBetMaxComponent(text,d,id){
        return (
            <InputNumber size="small" value={text} controls={false} onChange={(v)=>this.changeObjectAttr(id, v, "bet_max")}/>
        )
    }
    // 更新额外赔率属性
    changeObjectExtraAttr(id, v, attr, index){
        let multiples = this.state.multiples;
        for(let i=0;i<multiples.length;i++){
            if(multiples[i].bet_num === id){
                multiples[i][attr][index] = v;
                break;
            }
        }
        this.setState({multiples: multiples});
    }
    // 更新赔率属性
    changeObjectAttr(id, v, attr){
        let multiples = this.state.multiples;
        for(let i=0;i<multiples.length;i++){
            if(multiples[i].bet_num === id){
                multiples[i][attr] = v;
                break;
            }
        }
        this.setState({multiples: multiples}, ()=>{
            console.log(this.state.multiples);
        });
    }
    render() {
        const columns = [
            {
                title: '投注选项',
                dataIndex: 'name',
                key: 'name',
                render: (text,d)=>this.getBetNameComponent(text,d,d.id)
            },
            {
                title: '赔率(含本金)',
                dataIndex: 'multiple',
                key: 'multiple',
                render: (text,d)=>this.getMultipleComponent(text,d,d.id)
            },
            {
                title: '13/14赔率(含本金)',
                dataIndex: 'multiple_extra',
                key: 'multiple_extra',
                render: (text,d)=>this.getMultipleExtraComponent(text,d,d.id)
            },
            {
                title: '单期投注限额(元)',
                dataIndex: 'max',
                key: 'max',
                render: (text,d)=>this.getBetMaxComponent(text,d,d.id)
            },
            {
                title: '投注选项',
                dataIndex: 'name2',
                key: 'name2',
                render: (text,d)=>this.getBetNameComponent(text,d,d.id2)
            },
            {
                title: '赔率(含本金)',
                dataIndex: 'multiple2',
                key: 'multiple2',
                render: (text,d)=>this.getMultipleComponent(text,d,d.id2)
            },
            {
                title: '13/14赔率(含本金)',
                dataIndex: 'multiple2_extra',
                key: 'multiple2_extra',
                render: (text,d)=>this.getMultipleExtraComponent(text,d,d.id2)
            },
            {
                title: '单期投注限额(元)',
                dataIndex: 'max2',
                key: 'max2',
                render: (text,d)=>this.getBetMaxComponent(text,d,d.id2)
            },
        ];
        // 组合赔率表
        let multipleData = [
            this.getMultipleCombinationData(BetNum.BetNew28Num0, BetNum.BetNew28Num27),
            this.getMultipleCombinationData(BetNum.BetNew28Num1, BetNum.BetNew28Num26),
            this.getMultipleCombinationData(BetNum.BetNew28Num2, BetNum.BetNew28Num25),
            this.getMultipleCombinationData(BetNum.BetNew28Num3, BetNum.BetNew28Num24),
            this.getMultipleCombinationData(BetNum.BetNew28Num4, BetNum.BetNew28Num23),
            this.getMultipleCombinationData(BetNum.BetNew28Num5, BetNum.BetNew28Num22),
            this.getMultipleCombinationData(BetNum.BetNew28Num6, BetNum.BetNew28Num21),
            this.getMultipleCombinationData(BetNum.BetNew28Num7, BetNum.BetNew28Num20),
            this.getMultipleCombinationData(BetNum.BetNew28Num8, BetNum.BetNew28Num19),
            this.getMultipleCombinationData(BetNum.BetNew28Num9, BetNum.BetNew28Num18),
            this.getMultipleCombinationData(BetNum.BetNew28Num10, BetNum.BetNew28Num17),
            this.getMultipleCombinationData(BetNum.BetNew28Num11, BetNum.BetNew28Num16),
            this.getMultipleCombinationData(BetNum.BetNew28Num12, BetNum.BetNew28Num15),
            this.getMultipleCombinationData(BetNum.BetNew28Num13, BetNum.BetNew28Num14),
            this.getMultipleCombinationData(BetNum.BetNew28NumBig, BetNum.BetNew28NumSmall),
            this.getMultipleCombinationData(BetNum.BetNew28NumSingle, BetNum.BetNew28NumDouble),
            this.getMultipleCombinationData(BetNum.BetNew28NumBigSingle, BetNum.BetNew28NumSmallDouble),
            this.getMultipleCombinationData(BetNum.BetNew28NumSmallSingle, BetNum.BetNew28NumBigDouble),
            this.getMultipleCombinationData(BetNum.BetNew28NumSmallPeek, BetNum.BetNew28NumBigPeek),
            this.getMultipleCombinationData(BetNum.BetNew28NumDragon, BetNum.BetNew28TypeLeopard),
            this.getMultipleCombinationData(BetNum.BetNew28NumTiger, BetNum.BetNew28TypeSequence),
            this.getMultipleCombinationData(BetNum.BetNew28NumLeopard, BetNum.BetNew28TypePair),
        ];
        return (
            <Col span={24} className="rule-block">
                <Divider plain orientation="left"><Tag color="#ff5722">赔率表</Tag></Divider>
                <Table bordered={true} rowKey="id" size="small" dataSource={multipleData} columns={columns} pagination={false} className="multiple-table"/>
            </Col>
        );
    }
}

export default Multiple;

