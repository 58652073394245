import React, {Component} from 'react';
import './style.less';
import {Avatar, Button, Col, Descriptions, Input, InputNumber, message, Row, Table, Tag} from "antd";
import {getRequest, postRequest} from "../../../../common/util/request";
import {formatYuan} from "../../../../common/util/number";
import copy from 'copy-to-clipboard';
import {emitter, EVENTS} from "../../../../common/util/event";
import {MenuKey} from "../../../../common/constant/common";
import dayjs from "dayjs";
import AdminModal from "./adminModal";

class UserInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: this.props.user_id,
            avatar: '', // 头像
            nickname: '', // 昵称
            remark: '', // 备注
            type: 3, // 用户类型
            points: 0, // 余额
            lock_points: 0, // 冻结余额
            deposit_amount: 0, // 充值金额
            withdraw_amount: 0, // 提现金额
            wage: 0, // 流水
            wage_rebate_rate: 0, // 流水返利点位(千分比)
            today_deposit_amount: 0, // 当日充值金额
            today_withdraw_amount: 0, // 当日提现金额
            today_wage: 0, // 当日流水
            winlose: 0, // 总输赢
            wage_rebate: 0, // 总流水返利
            is_bot: false, // 是否是机器人
            recently_deposit: {
                total: 0,
                amount: 0,
                list: [],
            }, // 最近一个月充值
            recently_withdraw: {
                total: 0,
                amount: 0,
                list: [],
            }, // 最近一个月提现
            day_winlose: {
                total: 0,
                amount: 0,
                list: [],
            }, // 最近一个月游戏输赢
            adminModal: null, // 积分操作弹窗
        };
        this.getUserDetail = this.getUserDetail.bind(this);
        this.updateRemark = this.updateRemark.bind(this);
        this.refresh = this.refresh.bind(this);
        this.copyUserId = this.copyUserId.bind(this);
        this.goToBetRecord = this.goToBetRecord.bind(this);
        this.showAdminModal = this.showAdminModal.bind(this);
        this.getDepositList = this.getDepositList.bind(this);
        this.goToDepositRecord = this.goToDepositRecord.bind(this);
        this.goToWithdrawRecord = this.goToWithdrawRecord.bind(this);
        this.goToWinloseRecord = this.goToWinloseRecord.bind(this);
        this.goToPointsRecord = this.goToPointsRecord.bind(this);
        this.updateWageRebateRate = this.updateWageRebateRate.bind(this);
        this.setUserType = this.setUserType.bind(this);
    }
    componentDidMount() {
        this.refresh();
    }
    // 获取用户信息
    getUserDetail(){
        getRequest("/admin/user/detail", {user_id: this.state.user_id}, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                this.setState({
                    avatar: data.data.avatar,
                    nickname: data.data.nickname,
                    remark: data.data.remark,
                    type: data.data.type,
                    points: data.data.points,
                    lock_points: data.data.lock_points,
                    deposit_amount: data.data.deposit_amount,
                    withdraw_amount: data.data.withdraw_amount,
                    wage: data.data.wage,
                    wage_rebate_rate: data.data.wage_rebate_rate,
                    today_deposit_amount: data.data.today_deposit_amount,
                    today_withdraw_amount: data.data.today_withdraw_amount,
                    today_wage: data.data.today_wage,
                    winlose: data.data.winlose,
                    wage_rebate: data.data.wage_rebate,
                    is_bot: data.data.is_bot,
                });
            }
        });
    }
    // 更新备注
    updateRemark(){
        const postData = {
            user_id: this.state.user_id,
            remark: this.state.remark,
        };
        postRequest("/admin/user/remark/set", postData, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                message.success("更新备注成功");
            }
        });
    }
    // 更新流水返利点位
    updateWageRebateRate(){
        const postData = {
            user_id: this.state.user_id,
            rate: this.state.wage_rebate_rate,
        };
        postRequest("/admin/user/rate/set", postData, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                message.success("更新流水返利点位成功");
            }
        });
    }
    // 刷新页面
    refresh(){
        this.getUserDetail();
        this.getDepositList();
        this.getWithdrawList();
        this.getUserWinlose();
    }
    // 复制ID
    copyUserId(){
        copy(this.state.user_id);
        message.success("复制成功");
    }
    // 跳转投注记录
    goToBetRecord(){
        const payload = {
            user_id: this.state.user_id,
        };
        emitter.emit(EVENTS.SHOW_MENU, MenuKey.GameBetRecord.id, payload);
    }
    // 跳转充值记录
    goToDepositRecord(){
        const payload = {
            type: 1,
            user_id: this.state.user_id,
            status: 1,
        };
        emitter.emit(EVENTS.SHOW_MENU, MenuKey.PaymentList.id, payload);
    }
    // 跳转提现记录
    goToWithdrawRecord(){
        const payload = {
            type: 2,
            user_id: this.state.user_id,
            status: 1,
        };
        emitter.emit(EVENTS.SHOW_MENU, MenuKey.PaymentList.id, payload);
    }
    // 跳转输赢记录
    goToWinloseRecord(){
        const payload = {
            user_id: this.state.user_id,
        };
        emitter.emit(EVENTS.SHOW_MENU, MenuKey.UserWinLose.id, payload);
    }
    // 跳转积分记录
    goToPointsRecord(){
        const payload = {
            user_id: this.state.user_id,
        };
        emitter.emit(EVENTS.SHOW_MENU, MenuKey.UserPointsRecord.id, payload);
    }
    // 显示弹窗
    showAdminModal(){
        this.setState({adminModal: {user_id: this.state.user_id}});
    }
    // 获取充值列表
    getDepositList(){
        const query = {
            type: 1,
            user_id: this.state.user_id,
            start_at: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
            end_at: '',
            status: 1,
            page: 1,
            page_size: 12,
        };
        getRequest("/admin/payment/list", query, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                this.setState({recently_deposit: data.data});
            }
        })
    }
    // 获取提现列表
    getWithdrawList(){
        const query = {
            type: 2,
            user_id: this.state.user_id,
            start_at: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
            end_at: '',
            status: 1,
            page: 1,
            page_size: 12,
        };
        getRequest("/admin/payment/list", query, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                this.setState({recently_withdraw: data.data});
            }
        })
    }
    // 获取游戏输赢
    getUserWinlose(){
        const query = {
            user_id: this.state.user_id,
            start_at: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
            end_at: '',
            page: 1,
            page_size: 12,
        };
        getRequest("/admin/user/winlose", query, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                this.setState({day_winlose: data.data});
            }
        })
    }
    // 修改用户类型
    setUserType(type){
        postRequest("/admin/user/type/set",{  user_id: this.state.user_id,type:type}, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                message.success("修改成功");
                this.getUserDetail();
            }
        })
    }
    render() {
        const winloseColumns = [
            {
                title: '时间',
                dataIndex: 'date',
                key: 'date',
            },
            {
                title: '下注期数',
                dataIndex: 'bet_count',
                key: 'bet_count',
            },
            {
                title: '单期最高投注',
                dataIndex: 'bet_max_amount',
                key: 'bet_max_amount',
                render: (text,d)=>{
                    return formatYuan(text)
                }
            },
            {
                title: '当日流水',
                dataIndex: 'wage',
                key: 'wage',
                render: (text,d)=>{
                    return formatYuan(text)
                }
            },
            {
                title: '流水比',
                dataIndex: 'rate',
                key: 'rate',
                render: (text,d)=>{
                    return parseFloat((d.wage/d.bet_max_amount).toFixed(2));
                }
            },
            {
                title: '已领流水返利',
                dataIndex: 'wage_rebate',
                key: 'wage_rebate',
                render: (text,d)=>{
                    return formatYuan(text)
                }
            },
            {
                title: '当日输赢',
                dataIndex: 'points',
                key: 'points',
                render: (text,d)=>{
                    return <span className={text > 0 ? "number-lose" : text === 0 ? "" : "number-win"}>{formatYuan(text)}</span>
                }
            },
        ];
        const columns = [
            {
                title: '时间',
                dataIndex: 'created_at',
                key: 'created_at',
            },
            {
                title: '金额(元)',
                dataIndex: 'amount',
                key: 'amount',
                render: (text,d)=>{
                    return text.toLocaleString()
                }
            },
        ];
        const summaryDeposit = (pageData) => {
            return (
                <>
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={2}>总计: {this.state.recently_deposit.amount.toLocaleString()} / 共{this.state.recently_deposit.total}笔</Table.Summary.Cell>
                    </Table.Summary.Row>
                </>
            );
        }
        const summaryWithdraw = (pageData) => {
            return (
                <>
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={2}>总计: {this.state.recently_withdraw.amount.toLocaleString()} / 共{this.state.recently_withdraw.total}笔</Table.Summary.Cell>
                    </Table.Summary.Row>
                </>
            );
        }
        const summaryWinlose = (pageData) => {
            return (
                <>
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={7}>总计: <span className={this.state.day_winlose.amount > 0 ? "number-lose" : this.state.day_winlose.amount === 0 ? "" : "number-win"}>{formatYuan(this.state.day_winlose.amount)}</span> / 共{this.state.day_winlose.total}天</Table.Summary.Cell>
                    </Table.Summary.Row>
                </>
            );
        }
        return (
            <div className="user-info-panel">
                <Descriptions bordered size="small" column={4} className="user-description">
                    <Descriptions.Item label="用户ID">
                        <span className="user-id" onClick={this.copyUserId}>{this.state.user_id}</span>
                        <Button size="small" onClick={this.refresh}>刷新</Button>
                    </Descriptions.Item>
                    <Descriptions.Item label="昵称">
                        <div className="user-info">
                            <Avatar src={this.state.avatar} size={24}/>
                            <span className="nickname">{this.state.nickname}</span>
                            {
                                this.state.type === 1 ? <Tag color="#8bc34a">机器人</Tag> : this.state.type === 2 ? <Tag color="#607d8b">游戏托</Tag> : <Tag color="#03a9f4">普通用户</Tag>
                            }
                            {
                                this.state.is_bot ? null : this.state.type === 2 ? <Button size="small" type="primary" className="btn-primary" onClick={()=>this.setUserType(3)}>设为普通玩家</Button> :
                                    <Button size="small" type="primary" className="btn-warning" onClick={()=>this.setUserType(2)}>设为游戏托</Button>
                            }
                        </div>
                    </Descriptions.Item>
                    <Descriptions.Item label="备注">
                        <Input size="small" value={this.state.remark} className="input-remark" onChange={(e)=>{this.setState({remark: e.target.value})}}/>
                        <Button size="small" type="primary" onClick={this.updateRemark}>设置</Button>
                    </Descriptions.Item>
                    <Descriptions.Item label="流水返利点位(%)">
                        <InputNumber size="small" controls={false} min={0} max={100} step={0.1} value={this.state.wage_rebate_rate/10} onChange={(v)=>{this.setState({wage_rebate_rate: parseInt(v*10)})}}/>
                        <Button size="small" type="primary" className="btn-deposit btn-success" onClick={this.updateWageRebateRate}>修改</Button>
                    </Descriptions.Item>
                    <Descriptions.Item label="余额">
                        {formatYuan(this.state.points)}
                        <Button size="small" type="primary" className="btn-deposit btn-success" onClick={this.showAdminModal}>修改</Button>
                    </Descriptions.Item>
                    <Descriptions.Item label="总充值">{this.state.deposit_amount.toLocaleString()}</Descriptions.Item>
                    <Descriptions.Item label="总提现">{this.state.withdraw_amount.toLocaleString()}</Descriptions.Item>
                    <Descriptions.Item label="总流水">{formatYuan(this.state.wage)}</Descriptions.Item>
                    <Descriptions.Item label="冻结金额">{formatYuan(this.state.lock_points)}</Descriptions.Item>
                    <Descriptions.Item label="当日充值">{this.state.today_deposit_amount.toLocaleString()}</Descriptions.Item>
                    <Descriptions.Item label="当日提现">{this.state.today_withdraw_amount.toLocaleString()}</Descriptions.Item>
                    <Descriptions.Item label="当日流水">{formatYuan(this.state.today_wage)}</Descriptions.Item>
                    <Descriptions.Item label="操作" className="btn-block" span={2}>
                        <Button size="small" type="primary" className="btn-info" onClick={this.goToPointsRecord}>分值变化</Button>
                        <Button size="small" type="primary" className="btn-warning" onClick={this.goToBetRecord}>投注记录</Button>
                        <Button size="small" type="primary" onClick={this.goToWinloseRecord}>游戏输赢</Button>
                        <Button size="small" type="primary" className="btn-success" onClick={this.goToDepositRecord}>充值记录</Button>
                        <Button size="small" type="primary" className="btn-danger" onClick={this.goToWithdrawRecord}>提现记录</Button>
                    </Descriptions.Item>
                    <Descriptions.Item label="游戏总输赢">
                        <span className={this.state.winlose > 0 ? "number-lose" : this.state.winlose === 0 ? "" : "number-win"}>{formatYuan(this.state.winlose)}</span>
                    </Descriptions.Item>
                    <Descriptions.Item label="总流水返利">{formatYuan(this.state.wage_rebate)}</Descriptions.Item>
                </Descriptions>
                {
                    this.state.adminModal === null ? null :
                        <AdminModal data={this.state.adminModal} onCancel={()=>this.setState({adminModal: null})} onOk={this.refresh}/>
                }
                <Tag className="tag-label" color="#607d8b">近期输赢</Tag>
                <Table bordered={true} rowKey="id" size="small" dataSource={this.state.day_winlose.list} columns={winloseColumns} pagination={false} summary={summaryWinlose} className="winlose-table"/>
                <Row className="payment-wrapper">
                    <Col span={12} className="deposit-card-wrapper">
                        <Tag className="tag-label" color="#4CAF50">近期充值</Tag>
                        <Table bordered={true} rowKey="id" size="small" dataSource={this.state.recently_deposit.list} columns={columns} pagination={false} summary={summaryDeposit}/>
                    </Col>
                    <Col span={12} className="withdraw-card-wrapper">
                        <Tag className="tag-label" color="#f44336">近期提现</Tag>
                        <Table bordered={true} rowKey="id" size="small" dataSource={this.state.recently_withdraw.list} columns={columns} pagination={false} summary={summaryWithdraw}/>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default UserInfo;

