import React, {Component} from 'react';
import {Col, Divider, Input, Row, Tag} from "antd";

class MessagePrompt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            1: [], // 开盘后
            2: [], // 封盘后
            3: [], // 开奖后
            4: [], // 结算后
        };
        this.updateAttr = this.updateAttr.bind(this);
        this.getValue = this.getValue.bind(this);
    }
    componentDidMount() {
    }
    componentWillReceiveProps(nextProps, nextContext) {
        this.setState(nextProps.data);
    }
    // 更新指令
    updateAttr(v,index,attr){
        let list = this.state[attr];
        list[index] = v;
        this.props.onUpdate(list,"prompt",attr);
    }
    // 获取值
    getValue(){
        return this.state;
    }
    render() {
        return (
            <Col span={24} className="rule-block prompt-block">
                <Divider plain orientation="center"><Tag color="#ff5722">消息提示</Tag>(多条记录,则间隔1秒发送)</Divider>
                <Row>
                    <Col span={6} className="wrapper-box">
                        <div className="input-box">
                            <label className="label">开盘后: </label>
                            <div className="form-box">
                                <Input.TextArea autoSize={{minRows: 3, maxRows: 3}} size="small" value={this.state[1][0] === undefined ? '' : this.state[1][0]} placeholder="为空时不提醒" onChange={(v)=>this.updateAttr(v.target.value, 0,"1")}/>
                                <Input.TextArea autoSize={{minRows: 3, maxRows: 3}} size="small" value={this.state[1][1] === undefined ? '' : this.state[1][1]} placeholder="为空时不提醒" onChange={(v)=>this.updateAttr(v.target.value, 1,"1")}/>
                                <Input.TextArea autoSize={{minRows: 3, maxRows: 3}} size="small" value={this.state[1][1] === undefined ? '' : this.state[1][2]} placeholder="为空时不提醒" onChange={(v)=>this.updateAttr(v.target.value, 2,"1")}/>
                            </div>
                        </div>
                    </Col>
                    <Col span={6} className="wrapper-box">
                        <div className="input-box">
                            <label className="label">封盘后: </label>
                            <div className="form-box">
                                <Input.TextArea autoSize={{minRows: 3, maxRows: 3}} size="small" value={this.state[2][0] === undefined ? '' : this.state[2][0]} placeholder="为空时不提醒" onChange={(v)=>this.updateAttr(v.target.value, 0,"2")}/>
                                <Input.TextArea autoSize={{minRows: 3, maxRows: 3}} size="small" value={this.state[2][1] === undefined ? '' : this.state[2][1]} placeholder="为空时不提醒" onChange={(v)=>this.updateAttr(v.target.value, 1,"2")}/>
                                <Input.TextArea autoSize={{minRows: 3, maxRows: 3}} size="small" value={this.state[2][2] === undefined ? '' : this.state[2][2]} placeholder="为空时不提醒" onChange={(v)=>this.updateAttr(v.target.value, 2,"2")}/>
                            </div>
                        </div>
                    </Col>
                    <Col span={6} className="wrapper-box">
                        <div className="input-box">
                            <label className="label">开奖后: </label>
                            <div className="form-box">
                                <Input.TextArea autoSize={{minRows: 3, maxRows: 3}} size="small" value={this.state[3][0] === undefined ? '' : this.state[3][0]} placeholder="为空时不提醒" onChange={(v)=>this.updateAttr(v.target.value, 0,"3")}/>
                                <Input.TextArea autoSize={{minRows: 3, maxRows: 3}} size="small" value={this.state[3][1] === undefined ? '' : this.state[3][1]} placeholder="为空时不提醒" onChange={(v)=>this.updateAttr(v.target.value, 1,"3")}/>
                                <Input.TextArea autoSize={{minRows: 3, maxRows: 3}} size="small" value={this.state[3][2] === undefined ? '' : this.state[3][2]} placeholder="为空时不提醒" onChange={(v)=>this.updateAttr(v.target.value, 2,"3")}/>
                            </div>
                        </div>
                    </Col>
                    <Col span={6} className="wrapper-box">
                        <div className="input-box">
                            <label className="label">结算后: </label>
                            <div className="form-box">
                                <Input.TextArea autoSize={{minRows: 3, maxRows: 3}} size="small" value={this.state[4][0] === undefined ? '' : this.state[4][0]} placeholder="为空时不提醒" onChange={(v)=>this.updateAttr(v.target.value, 0,"4")}/>
                                <Input.TextArea autoSize={{minRows: 3, maxRows: 3}} size="small" value={this.state[4][1] === undefined ? '' : this.state[4][1]} placeholder="为空时不提醒" onChange={(v)=>this.updateAttr(v.target.value, 1,"4")}/>
                                <Input.TextArea autoSize={{minRows: 3, maxRows: 3}} size="small" value={this.state[4][2] === undefined ? '' : this.state[4][2]} placeholder="为空时不提醒" onChange={(v)=>this.updateAttr(v.target.value, 2,"4")}/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        );
    }
}

export default MessagePrompt;

