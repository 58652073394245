// 全局变量
export let globalConfig = {
    business: {
        id: 0,
        nickname: '',
        account: '',
        avatar: '',
        greeting: '',
        ring: ""
    }
}

// 读取token
export function getToken(){
    return localStorage.getItem("token");
}

// 写入token
export function saveToken(token){
    localStorage.setItem("token", token);
}

// 清除token
export function clearToken(){
    localStorage.removeItem("token");
}

// 校验是否登陆
export function CheckLogin(){
    let token = getToken();
    return token !== null && token !== "";
}

// 颜色数组
const colorArr = ['#f50', '#2db7f5', '#87d068', '#108ee9', '#faad14', '#9c27b0', '#ffeb3b', '#607d8b'];

// 获取颜色
export function getColor(index){
    index = index % colorArr.length;
    return colorArr[index];
}
