// 不足10的首位添0
export function tc(num){
    if(num < 0) {
        return '00';
    } else if(num < 10){
        return '0' + num;
    }else {
        return num;
    }
}

// 格式化金钱(元)
export function formatYuan(num){
    const v = parseInt(num / 1000);
    return v.toLocaleString();
}

// 秒数转mm:ss
export function formatSecond(duration){
    duration = parseInt(duration);
    let hour = parseInt(duration / 3600);
    duration = duration % 3600;
    let minute = parseInt(duration / 60);
    let second = duration % 60;
    if(hour < 10){
        hour = "0" + hour;
    }
    if(minute < 10){
        minute = "0" + minute;
    }
    if(second < 10){
        second = "0" + second;
    }
    return hour + ":" + minute + ":" + second;
}

// 随机ID
export function getUniqueID(){
    return Math.random().toString(36).substr(2);
}