import React,{Component} from 'react';
import {Button, Input, InputNumber, message, Modal} from "antd";
import {postRequest} from "../../../../common/util/request";

class AddModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            check: false,
            name: this.props.data.name ? this.props.data.name : '',
            multiple_min: this.props.data.multiple_min ? this.props.data.multiple_min : 1,
            multiple_max: this.props.data.multiple_max ? this.props.data.multiple_max : 100,
            bet_amount: this.props.data.bet_amount ? this.props.data.bet_amount : 0,
            bet_str: this.props.data.bet_str ? this.props.data.bet_str : '',
        };
        this.add = this.add.bind(this);
        this.check = this.check.bind(this);
        this.changeMultipleMin = this.changeMultipleMin.bind(this);
        this.changeMultipleMax = this.changeMultipleMax.bind(this);
    }
    componentDidMount() {
    }
    // 校验
    check(){
        const betStr = this.state.bet_str;
        if(betStr === ""){
            message.warning("请先输入下注详情");
            return;
        }
        postRequest("/admin/game/bet/parse", {bet_str: betStr}, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                message.success("校验成功");
                this.setState({check: true, bet_amount: data.data.sum, bet_str: betStr});
            }
        })
    }
    // 更改最小赔率
    changeMultipleMin(v){
        this.setState({multiple_min: v});
    }
    // 更改最大赔率
    changeMultipleMax(v){
        this.setState({multiple_max: v});
    }
    // 添加
    add(){
        const name = this.state.name;
        if(name === ""){
            message.warning("请输入模式名");
            return;
        }
        if(!this.state.check){
            message.warning("请先校验下注模式");
            return;
        }
        if(this.state.bet_amount === 0){
            message.warning("下注金额为0");
            return;
        }
        const obj = {
            name: name,
            bet_str: this.state.bet_str,
            bet_amount: this.state.bet_amount,
            multiple_min: this.state.multiple_min,
            multiple_max: this.state.multiple_max,
        };
        this.props.onOk(obj);
    }
    render() {
        return (
            <Modal
                className="add-bet-model-modal"
                open={true}
                onCancel={this.props.onCancel}
                title={this.props.data.name === undefined ? '添加模式' : "编辑模式"}
                maskClosable={false}
                onOk={this.add}
            >
                <div className="form-div">
                    <label>模式名:</label>
                    <Input value={this.state.name} placeholder="请输入模式名称" onChange={(e)=>this.setState({name: e.target.value})}/>
                </div>
                <div className="form-div">
                    <label>下注详情:</label>
                    <Input value={this.state.bet_str} placeholder="请输入下注详情" onChange={(e)=>this.setState({bet_str: e.target.value})}/>
                    <Button type="primary" onClick={this.check}>校验</Button>
                </div>
                <div className="form-div">
                    <label>最小倍率:</label>
                    <InputNumber controls={false} value={this.state.multiple_min} onChange={this.changeMultipleMin} min={1} max={10000} />
                </div>
                <div className="form-div">
                    <label>最大倍率:</label>
                    <InputNumber controls={false} value={this.state.multiple_max} onChange={this.changeMultipleMax} min={1} max={10000} />
                </div>
                <div className="form-div">
                    <label>下注金额:</label>
                    <InputNumber controls={false} value={this.state.multiple_min*this.state.bet_amount} disabled/> ~ <InputNumber controls={false} value={this.state.multiple_max*this.state.bet_amount} disabled/>
                </div>
            </Modal>
        );
    }
}

export default AddModal;

