import React, {Component} from 'react';
import './style.less';
import {Button, message, Table, DatePicker} from "antd";
import {getRequest} from "../../../../common/util/request";
import {emitter} from "../../../../common/util/event";
import {MenuKey, rangeMonthPresets} from "../../../../common/constant/common";
import {SearchOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import {formatYuan} from "../../../../common/util/number";

class CenterBank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            start_at: dayjs().startOf("month"),
            end_at: dayjs(),
            list: [],
            keyword: '',
        };
        this.getList = this.getList.bind(this);
        this.pageLoaded = this.pageLoaded.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
    }
    componentDidMount() {
        emitter.addListener(MenuKey.CenterBank.id, this.pageLoaded);
    }
    componentWillUnmount() {
        emitter.removeListener(MenuKey.CenterBank.id, this.pageLoaded);
    }
    // 页面加载
    pageLoaded(payload){
        this.getList();
    }
    // 获取列表
    getList(){
        const query = {
            start_at: this.state.start_at.format('YYYY-MM-DD'),
            end_at: this.state.end_at.format('YYYY-MM-DD'),
        };
        getRequest("/admin/centerbank/list", query, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                this.setState({list: data.data});
            }
        })
    }
    // 更改日期
    onChangeDate(value){
        this.setState({start_at: value[0], end_at: value[1]});
    }
    render() {
        const columns = [
            {
                title: '日期',
                dataIndex: 'date',
                key: 'date',
            },
            {
                title: '充值金额',
                dataIndex: 'deposit',
                key: 'deposit',
            },
            {
                title: '提现金额',
                dataIndex: 'withdraw',
                key: 'withdraw',
            },
            {
                title: '游戏总流水',
                dataIndex: 'wage',
                key: 'wage',
                render: (text,d)=>{
                    return formatYuan(text)
                }
            },
            {
                title: '13/14流水',
                dataIndex: 'wage_extra',
                key: 'wage_extra',
                render: (text,d)=>{
                    let percent = '0';
                    if(d.wage !== 0){
                        percent = (d.wage_extra*100/d.wage).toFixed(2);
                    }
                    return formatYuan(text)+'('+percent+'%)';
                }
            },
            {
                title: '流水返利',
                dataIndex: 'wage_rebates',
                key: 'wage_rebates',
                render: (text,d)=>{
                    return formatYuan(text)
                }
            },
            {
                title: '游戏输赢',
                children: [
                    {
                        title: '加拿大28',
                        dataIndex: 'gamecan28',
                        key: 'gamecan28',
                        render: (text,d)=>{
                            const amt = text;
                            return <span className={amt > 0 ? "number-lose" : amt === 0 ? "" : "number-win"}>{formatYuan(amt)}</span>
                        }
                    },
                    {
                        title: '台湾28',
                        dataIndex: 'gametwdd28',
                        key: 'gametwdd28',
                        render: (text,d)=>{
                            const amt = text;
                            return <span className={amt > 0 ? "number-lose" : amt === 0 ? "" : "number-win"}>{formatYuan(amt)}</span>
                        }
                    },
                    {
                        title: '合计',
                        dataIndex: 'game_winlose',
                        key: 'game_winlose',
                        render: (text,d)=>{
                            const amt = d.gamecan28 + d.gametwdd28;
                            return <span className={amt > 0 ? "number-lose" : amt === 0 ? "" : "number-win"}>{formatYuan(amt)}</span>
                        }
                    }
                ],
            },
            {
                title: '玩家总分',
                dataIndex: 'remain_points',
                key: 'remain_points',
                render: (text,d)=>{
                    return formatYuan(text)
                }
            },
        ];
        return (
            <div className="center-bank-panel">
                <div className="filter-block">
                    <DatePicker.RangePicker presets={rangeMonthPresets} allowClear={false} value={[this.state.start_at,this.state.end_at]} onChange={this.onChangeDate}/>
                    <Button type="primary" icon={<SearchOutlined />} onClick={this.getList}>查询</Button>
                </div>
                <Table
                    bordered={true}
                    rowKey="id"
                    size="small"
                    dataSource={this.state.list}
                    columns={columns}
                    pagination={false}
                />
            </div>
        );
    }
}

export default CenterBank;

