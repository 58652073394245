import React, {Component} from 'react';
import './style.less';
import {Row, Col, message, Button, Spin} from "antd";
import {getRequest, postRequest} from "../../../../common/util/request";
import {emitter} from "../../../../common/util/event";
import {MenuKey} from "../../../../common/constant/common";
import BetLimit from "./betLimit";
import BetCommon from "./betCommon";
import Multiple from "./multiple";

class SettingGame extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config_key: "game_config", // 配置key
            loading: true,
            multiples: [], // 赔率
            bet_limit: {}, // 投注限制
            common: {}, // 通用配置
        };
        this.updateConfig = this.updateConfig.bind(this);
        this.getCurrentConfig = this.getCurrentConfig.bind(this);
        this.getConfig = this.getConfig.bind(this);
        this.pageLoaded = this.pageLoaded.bind(this);
        this.updateAttr = this.updateAttr.bind(this);
        this.betLimitRef = React.createRef();
        this.betCommonRef = React.createRef();
        this.multipleRef = React.createRef();
    }
    componentDidMount() {
        emitter.addListener(MenuKey.SettingGame.id, this.pageLoaded);
    }
    componentWillUnmount() {
        emitter.removeListener(MenuKey.SettingGame.id, this.pageLoaded);
    }
    // 页面加载
    pageLoaded(payload){
        // 初始化游戏配置
        this.getConfig();
    }
    // 获取游戏配置
    getConfig(){
        getRequest("/admin/config/info", {key: this.state.config_key}, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                const obj = JSON.parse(data.data.value);
                obj.loading = false;
                this.setState(obj, ()=>{
                    this.multipleRef.current.initData(obj.multiples);
                });
            }
        })
    }
    // 获取当前配置
    getCurrentConfig(){
        return {
            multiples: this.multipleRef.current.getValue(),
            bet_limit: this.betLimitRef.current.getValue(),
            common: this.betCommonRef.current.getValue(),
        }
    }
    // 更新游戏配置
    updateConfig(){
        // 判断是否有改变, 无改变则不更新
        const newConfig = this.getCurrentConfig()
        const jsonStr = JSON.stringify(newConfig);
        postRequest("/admin/config/set", {key: this.state.config_key, value: jsonStr}, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                message.success("配置已更新");
            }
        })
    }
    // 更新指令
    updateAttr(v,attr, attrSub){
        const obj = this.state[attr];
        obj[attrSub] = v;
        const updateObj = {};
        updateObj[attr] = obj;
        this.setState(updateObj);
    }
    render() {
        return (
            <div className="game-setting-panel">
                <Spin tip="加载中...." spinning={this.state.loading}>
                    <div className="btn-group">
                        <Button type="primary" className="btn-success" onClick={this.updateConfig}>保存配置</Button>
                    </div>
                    <Row>
                        <BetLimit ref={this.betLimitRef} data={this.state.bet_limit} onUpdate={this.updateAttr}/>
                        <BetCommon ref={this.betCommonRef} data={this.state.common} onUpdate={this.updateAttr}/>
                        <Col span={8} className="rule-block">
                        </Col>
                        <Multiple ref={this.multipleRef} data={this.state.multiples}/>
                    </Row>
                </Spin>
            </div>
        );
    }
}

export default SettingGame;

