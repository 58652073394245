import React, {Component} from 'react';
import {message} from "antd";
import {emitter,EVENTS} from "../../common/util/event";
import chaWav from '../../static/sound/cha.wav';
import huiWav from '../../static/sound/hui.wav';
import closeWav from '../../static/sound/close.wav';

class RingBell extends Component {
    constructor(props) {
        super(props);
        this.state = {
            audio: "https://ds28.s3.ap-east-1.amazonaws.com/customer_service/audios/20230918/ab5b7cb7dd934f58ab442bef455f7d74.mp3",
        };
        this.play = this.play.bind(this);
        this.ring = React.createRef();
    }
    componentDidMount() {
        emitter.addListener(EVENTS.RING_BELL, this.play);
    }
    componentWillUnmount(){
        emitter.removeListener(EVENTS.RING_BELL, this.play);
    }
    // 播放铃声
    play(type){
        let url = "";
        switch(type){
            case 1:
                url = chaWav;
                break;
            case 2:
                url = huiWav;
                break;
            case 3:
                url = closeWav;
                break;
            default:
                break;
        }
        if(url !== ''){
            this.ring.current.pause();
            this.ring.current.currentTime = 0;
            this.ring.current.src = url;
            this.ring.current.play().catch((error) => {
                document.addEventListener('click', () => {
                    this.ring.current.play();
                }, {once: true});
                message.warning("受Chrome浏览器策略影响, 无法播放铃声, 您需要手动点击下屏幕触发播放");
            });
        }
    }
    render() {
        return (
            <audio ref={this.ring} src={this.state.audio} controls={false} autoPlay={false}/>
        );
    }
}

export default RingBell;

