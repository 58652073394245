import {Component} from 'react';
import { withRouter } from "react-router-dom";
import {Tabs} from 'antd';
import './style.less';
import {emitter, EVENTS} from "../../../common/util/event";
import {MenuKey} from "../../../common/constant/common";
import UserInfo from "./userInfo";

// 用户昵称表
const userNameMap =  {};

class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: [MenuKey.Home.id],
            activeKey: MenuKey.Home.id,
        };
        this.showMenu = this.showMenu.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    componentDidMount() {
        emitter.addListener(EVENTS.SHOW_MENU, this.showMenu);
    }
    componentWillUnmount(){
        emitter.removeListener(EVENTS.SHOW_MENU, this.showMenu);
    }
    // 显示菜单
    showMenu(key, payload){
        // 用户详情特殊处理
        if(key === MenuKey.UserInfo.id){
            key = key + "_" + payload.user_id;
            userNameMap[payload.user_id] = payload.nickname;
        }
        const tabs = this.state.tabs;
        let exist = false;
        for(let i=0;i<tabs.length;i++){
            if(tabs[i] === key){
                exist = true;
                break;
            }
        }
        if(!exist){
            tabs.push(key);
        }
        this.setState({tabs: tabs, activeKey: key}, ()=>{
            emitter.emit(key, payload);
        });
    }
    // 获取菜单选项
    getMenuObject(key){
        for(let i in MenuKey){
            if(MenuKey[i].id === key){
                return MenuKey[i];
            }
        }
        return {};
    }
    // 编辑tab
    onEdit(targetKey, action){
        const tabs = this.state.tabs;
        let activeKey = this.state.activeKey;
        if(action === "remove"){
            for(let i=0;i<tabs.length;i++){
                if(tabs[i] === targetKey){
                    tabs.splice(i, 1);
                    // 默认显示上一个标签
                    if(activeKey === targetKey){
                        if(tabs.length > 0){
                            if(i === 0){
                                activeKey = tabs[0];
                            }else{
                                activeKey = tabs[i-1];
                            }
                        }
                    }
                    this.setState({tabs: tabs, activeKey: activeKey});
                }
            }
        }
    }
    // 切换面板
    onChange(key){
        this.setState({activeKey: key}, ()=>{
            // emitter.emit(key, {});
        });
    }
    render() {
        const items = [];
        for(let i=0;i<this.state.tabs.length;i++){
            const menuKey = this.state.tabs[i];
            let menu = {};
            // 用户详情标题处理
            if(menuKey.startsWith(MenuKey.UserInfo.id)){
                const lastIndex = menuKey.lastIndexOf("_");
                const userId = menuKey.substring(lastIndex+1);
                menu = {
                    label: `玩家-${userNameMap[userId]}`,
                    key: menuKey,
                    children: <UserInfo user_id={userId}/>,
                };
            }else{
                const obj = this.getMenuObject(this.state.tabs[i]);
                menu = {
                    label: obj.name,
                    key: obj.id,
                    children: obj.component,
                };
            }
            // 首页不可关闭
            if(menu.key === MenuKey.Home.id){
                menu.closeIcon = false;
            }
            items.push(menu);
        }
        return (
            <Tabs
                className="content-panel"
                activeKey={this.state.activeKey}
                type="editable-card"
                hideAdd={true}
                onEdit={this.onEdit}
                onChange={this.onChange}
                size="small"
                items={items}
            />
        );
    }
}

export default withRouter(Content);

