import React, {Component} from 'react';
import {emitter, EVENTS} from "../../../../common/util/event";
import {Card, Tag} from "antd";
import {formatYuan} from "../../../../common/util/number";
import {MenuKey} from "../../../../common/constant/common";

class GameHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            game_id: 1,
            list: [],
        };
        this.updateRoundInfo = this.updateRoundInfo.bind(this);
        this.viewBetRecord = this.viewBetRecord.bind(this);
    }
    componentDidMount() {
        emitter.addListener(EVENTS.ROUND_INFO, this.updateRoundInfo);
    }
    componentWillUnmount() {
        emitter.removeListener(EVENTS.ROUND_INFO, this.updateRoundInfo);
    }
    // 更新
    updateRoundInfo(data){
        this.setState({
            game_id: data.game_id,
            list: data.history.reverse(),
        });
    }
    // 查看投注记录
    viewBetRecord(id){
        const payload = {
            game_id: this.state.game_id,
            game_no: id,
        };
        emitter.emit(EVENTS.SHOW_MENU, MenuKey.GameBetRecord.id, payload);
    }
    render() {
        return (
            <Card className="game-history-card">
                <Tag className="tag-label" color="#607d8b">开奖历史</Tag>
                <table className="game-history-table">
                    <tbody>
                        <tr>
                            <td>期号</td>
                            {
                                this.state.list.map((item, i)=>{
                                    return (
                                        <td key={i} className="game-no" onClick={()=>this.viewBetRecord(item.game_no)}>{item.game_no}</td>
                                    )
                                })
                            }
                        </tr>
                        <tr>
                            <td>开奖结果</td>
                            {
                                this.state.list.map((item, i)=>{
                                    return (
                                        <td key={i}>
                                            {item.draw_result[0]}+{item.draw_result[1]}+{item.draw_result[2]}=<span className={item.draw_result[3]===13 || item.draw_result[3] === 14 ? "number-special" : ""}>{item.draw_result[3]}</span>
                                        </td>
                                    )
                                })
                            }
                        </tr>
                        <tr>
                            <td>下注人数</td>
                            {
                                this.state.list.map((item, i)=>{
                                    return (
                                        <td key={i}>{item.bet_user_count}</td>
                                    )
                                })
                            }
                        </tr>
                        <tr>
                            <td>下注金额</td>
                            {
                                this.state.list.map((item, i)=>{
                                    return (
                                        <td key={i}>{formatYuan(item.bet_amount)}</td>
                                    )
                                })
                            }
                        </tr>
                        <tr>
                            <td>输赢</td>
                            {
                                this.state.list.map((item, i)=>{
                                    const amount = item.winlose - item.bet_amount;
                                    return (
                                        <td key={i} className={amount > 0 ? "number-lose" : amount === 0 ? "" : "number-win"}>{formatYuan(amount)}</td>
                                    )
                                })
                            }
                        </tr>
                    </tbody>
                </table>
            </Card>
        );
    }
}

export default GameHistory;

