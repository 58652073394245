import {Component} from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Login from './login/index';
import Home from './home/index';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/zh-cn';
import BetRecord from "./betRecord";
import BetResult from "./betResult";

// 初始化
dayjs.extend(relativeTime);
dayjs.locale('zh-cn');

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {
    }
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" render={(props) => <Home {...props} />} />
                    <Route exact path="/login" render={(props) => <Login {...props} />} />
                    <Route exact path="/bet/record" render={(props) => <BetRecord {...props} />} />
                    <Route exact path="/bet/result" render={(props) => <BetResult {...props} />} />
                    <Redirect to="/" exact />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;

