import React, {Component} from 'react';
import './style.less';
import {Button, message, Table, DatePicker, Input} from "antd";
import {getRequest, host} from "../../../../common/util/request";
import {emitter, EVENTS} from "../../../../common/util/event";
import {MenuKey, rangePresets} from "../../../../common/constant/common";
import {SearchOutlined, ExportOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import {formatYuan} from "../../../../common/util/number";
import UserInfo from "../../../../common/component/userInfo";

class GameWinLose extends Component {
    constructor(props) {
        super(props);
        this.state = {
            start_at: dayjs(),
            end_at: dayjs(),
            list: [],
            keyword: '',
        };
        this.getGameWinLose = this.getGameWinLose.bind(this);
        this.pageLoaded = this.pageLoaded.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.changeKeyword = this.changeKeyword.bind(this);
        this.getFilterList = this.getFilterList.bind(this);
        this.export = this.export.bind(this);
    }
    componentDidMount() {
        emitter.addListener(MenuKey.GameWinLose.id, this.pageLoaded);
    }
    componentWillUnmount() {
        emitter.removeListener(MenuKey.GameWinLose.id, this.pageLoaded);
    }
    // 页面加载
    pageLoaded(payload){
        this.getGameWinLose();
    }
    // 获取列表
    getGameWinLose(){
        const query = {
            start_at: this.state.start_at.format('YYYY-MM-DD'),
            end_at: this.state.end_at.format('YYYY-MM-DD'),
        };
        getRequest("/admin/game/winlose", query, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                this.setState({list: data.data});
            }
        })
    }
    // 更改日期
    onChangeDate(value){
        this.setState({start_at: value[0], end_at: value[1]});
    }
    // 跳转投注记录
    goToBetRecord(userId){
        const payload = {
            user_id: userId,
        };
        emitter.emit(EVENTS.SHOW_MENU, MenuKey.GameBetRecord.id, payload);
    }
    // 跳转输赢记录
    goToWinloseRecord(userId){
        const payload = {
            user_id: userId,
        };
        emitter.emit(EVENTS.SHOW_MENU, MenuKey.UserWinLose.id, payload);
    }
    // 更改关键词
    changeKeyword(e){
        this.setState({keyword: e.target.value});
    }
    // 获取筛选后的列表
    getFilterList(){
        let list = [];
        for(let i=0;i<this.state.list.length;i++){
            const obj = this.state.list[i].user;
            if(obj.nickname.indexOf(this.state.keyword) !== -1 || obj.remark.indexOf(this.state.keyword) !== -1){
                list.push(this.state.list[i]);
            }
        }
        return list;
    }
    // 导出
    export(){
        const url = host+`/admin/game/winlose/export?start_at=${this.state.start_at.format('YYYY-MM-DD')}&end_at=${this.state.end_at.format('YYYY-MM-DD')}`;
        window.open(url);
    }
    render() {
        const columns = [
            {
                title: '用户',
                dataIndex: 'user',
                key: 'user',
                filters: [
                    {
                        text: '真人',
                        value: 3,
                    },
                    {
                        text: '假人',
                        value: 2,
                    },
                ],
                onFilter: (value, record) => {
                    console.log(value);
                    return record.user.type === value
                },
                render: (text,d)=>{
                    return (
                        <UserInfo data={text} />
                    )
                }
            },
            {
                title: '参与期数',
                dataIndex: 'bet_count',
                key: 'bet_count',
                sorter: {
                    compare: (a, b) => a.bet_count - b.bet_count,
                },
            },
            {
                title: '输赢(元)',
                dataIndex: 'winlose',
                key: 'winlose',
                sorter: {
                    compare: (a, b) => a.winlose - b.winlose,
                },
                render: (text,d)=>{
                    return formatYuan(text)
                }
            },
            {
                title: '流水(元)',
                dataIndex: 'wage',
                key: 'wage',
                sorter: {
                    compare: (a, b) => a.wage - b.wage,
                },
                render: (text,d)=>{
                    return formatYuan(text)
                }
            },
            {
                title: '余额(元)',
                dataIndex: 'points',
                key: 'points',
                sorter: {
                    compare: (a, b) => a.points - b.points,
                },
                render: (text,d)=>{
                    return formatYuan(text)
                }
            },
            {
                title: '操作',
                dataIndex: 'extra',
                key: 'extra',
                render: (text,d)=>{
                    return (
                        <div className="btn-block">
                            <Button size="small" type="primary" onClick={()=>this.goToWinloseRecord(d.user.user_id)}>游戏输赢</Button>
                            <Button size="small" className="btn-warning" onClick={()=>this.goToBetRecord(d.user.user_id)}>投注记录</Button>
                        </div>
                    )
                }
            },
        ];
        const summary = (pageData) => {
            let totalWinlose = 0;
            let totalWage = 0;
            let totalPoints = 0;

            pageData.forEach((d) => {
                totalWinlose += d.winlose;
                totalWage += d.wage;
                totalPoints += d.points;
            });

            return (
                <>
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>总计: {this.getFilterList().length}人</Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>-</Table.Summary.Cell>
                        <Table.Summary.Cell index={2}>{formatYuan(totalWinlose)}</Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>{formatYuan(totalWage)}</Table.Summary.Cell>
                        <Table.Summary.Cell index={4}>{formatYuan(totalPoints)}</Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>-</Table.Summary.Cell>
                    </Table.Summary.Row>
                </>
            );
        }
        return (
            <div className="game-winlose-panel">
                <div className="filter-block">
                    <Input className="keyword-input" value={this.state.keyword} placeholder="输入用户昵称过滤" onChange={this.changeKeyword}/>
                    <DatePicker.RangePicker presets={rangePresets} allowClear={false} value={[this.state.start_at,this.state.end_at]} onChange={this.onChangeDate}/>
                    <Button type="primary" icon={<SearchOutlined />} onClick={this.getGameWinLose}>查询</Button>
                    <Button type="primary" className="btn-warning" icon={<ExportOutlined />} onClick={this.export}>导出</Button>
                </div>
                <Table
                    bordered={true}
                    rowKey={(record) => record.user.user_id}
                    size="small"
                    dataSource={this.getFilterList()}
                    columns={columns}
                    pagination={false}
                    className="winlose-list-table"
                    summary={summary}
                />
            </div>
        );
    }
}

export default GameWinLose;

