// 下注选项
export const BetNum = {
    BetNew28Num0: 0,
    BetNew28Num1: 1,
    BetNew28Num2: 2,
    BetNew28Num3: 3,
    BetNew28Num4: 4,
    BetNew28Num5: 5,
    BetNew28Num6: 6,
    BetNew28Num7: 7,
    BetNew28Num8: 8,
    BetNew28Num9: 9,
    BetNew28Num10: 10,
    BetNew28Num11: 11,
    BetNew28Num12: 12,
    BetNew28Num13: 13,
    BetNew28Num14: 14,
    BetNew28Num15: 15,
    BetNew28Num16: 16,
    BetNew28Num17: 17,
    BetNew28Num18: 18,
    BetNew28Num19: 19,
    BetNew28Num20: 20,
    BetNew28Num21: 21,
    BetNew28Num22: 22,
    BetNew28Num23: 23,
    BetNew28Num24: 24,
    BetNew28Num25: 25,
    BetNew28Num26: 26,
    BetNew28Num27: 27,
    BetNew28NumSingle: 28,      // 单
    BetNew28NumBig: 29,         // 大
    BetNew28NumSmallSingle: 30, // 小单
    BetNew28NumBigSingle: 31,   // 大单
    BetNew28NumSmallPeek: 32,   // 极小
    BetNew28NumDouble: 33,      // 双
    BetNew28NumSmall: 34,       // 小
    BetNew28NumSmallDouble: 35, // 小双
    BetNew28NumBigDouble: 36,   // 大双
    BetNew28NumBigPeek: 37,     // 极大
    BetNew28NumDragon: 38,      // 龙
    BetNew28NumTiger: 39,       // 虎
    BetNew28NumLeopard: 40,     // 豹
    BetNew28TypeLeopard: 41,    // 豹子
    BetNew28TypeSequence: 42,   // 顺子
    BetNew28TypePair: 43,       // 对子
};

// 获取下注选项名
export function getBetNumName(k){
    switch(k){
        case BetNum.BetNew28Num0:
            return "0";
        case BetNum.BetNew28Num1:
            return "1";
        case BetNum.BetNew28Num2:
            return "2";
        case BetNum.BetNew28Num3:
            return "3";
        case BetNum.BetNew28Num4:
            return "4";
        case BetNum.BetNew28Num5:
            return "5";
        case BetNum.BetNew28Num6:
            return "6";
        case BetNum.BetNew28Num7:
            return "7";
        case BetNum.BetNew28Num8:
            return "8";
        case BetNum.BetNew28Num9:
            return "9";
        case BetNum.BetNew28Num10:
            return "10";
        case BetNum.BetNew28Num11:
            return "11";
        case BetNum.BetNew28Num12:
            return "12";
        case BetNum.BetNew28Num13:
            return "13";
        case BetNum.BetNew28Num14:
            return "14";
        case BetNum.BetNew28Num15:
            return "15";
        case BetNum.BetNew28Num16:
            return "16";
        case BetNum.BetNew28Num17:
            return "17";
        case BetNum.BetNew28Num18:
            return "18";
        case BetNum.BetNew28Num19:
            return "19";
        case BetNum.BetNew28Num20:
            return "20";
        case BetNum.BetNew28Num21:
            return "21";
        case BetNum.BetNew28Num22:
            return "22";
        case BetNum.BetNew28Num23:
            return "23";
        case BetNum.BetNew28Num24:
            return "24";
        case BetNum.BetNew28Num25:
            return "25";
        case BetNum.BetNew28Num26:
            return "26";
        case BetNum.BetNew28Num27:
            return "27";
        case BetNum.BetNew28NumSingle:
            return "单";
        case BetNum.BetNew28NumBig:
            return "大";
        case BetNum.BetNew28NumSmallSingle:
            return "小单";
        case BetNum.BetNew28NumBigSingle:
            return "大单";
        case BetNum.BetNew28NumSmallPeek:
            return "极小";
        case BetNum.BetNew28NumDouble:
            return "双";
        case BetNum.BetNew28NumSmall:
            return "小";
        case BetNum.BetNew28NumSmallDouble:
            return "小双";
        case BetNum.BetNew28NumBigDouble:
            return "大双";
        case BetNum.BetNew28NumBigPeek:
            return "极大";
        case BetNum.BetNew28NumDragon:
            return "龙";
        case BetNum.BetNew28NumTiger:
            return "虎";
        case BetNum.BetNew28NumLeopard:
            return "豹";
        case BetNum.BetNew28TypeLeopard:
            return "豹子";
        case BetNum.BetNew28TypeSequence:
            return "顺子";
        case BetNum.BetNew28TypePair:
            return "对子";
        default:
            return "";
    }
}
// 下注选项名
// export const BetNumName = {
//     BetNew28Num0: "0",
//     BetNew28Num1: "1",
//     BetNew28Num2: "2",
//     BetNew28Num3: "3",
//     BetNew28Num4: "4",
//     BetNew28Num5: "5",
//     BetNew28Num6: "6",
//     BetNew28Num7: "7",
//     BetNew28Num8: "8",
//     BetNew28Num9: "9",
//     BetNew28Num10: "10",
//     BetNew28Num11: "11",
//     BetNew28Num12: "12",
//     BetNew28Num13: "13",
//     BetNew28Num14: "14",
//     BetNew28Num15: "15",
//     BetNew28Num16: "16",
//     BetNew28Num17: "17",
//     BetNew28Num18: "18",
//     BetNew28Num19: "19",
//     BetNew28Num20: "20",
//     BetNew28Num21: "21",
//     BetNew28Num22: "22",
//     BetNew28Num23: "23",
//     BetNew28Num24: "24",
//     BetNew28Num25: "25",
//     BetNew28Num26: "26",
//     BetNew28Num27: "27",
//     BetNew28NumSingle: "单",
//     BetNew28NumBig: "大",
//     BetNew28NumSmallSingle: "小单",
//     BetNew28NumBigSingle: "大单",
//     BetNew28NumSmallPeek: "极小",
//     BetNew28NumDouble: "双",
//     BetNew28NumSmall: "小",
//     BetNew28NumSmallDouble: "小双",
//     BetNew28NumBigDouble: "大双",
//     BetNew28NumBigPeek: "极大",
//     BetNew28NumDragon: "龙",
//     BetNew28NumTiger: "虎",
//     BetNew28NumLeopard: "豹",
//     BetNew28TypeLeopard: "豹子",
//     BetNew28TypeSequence: "顺子",
//     BetNew28TypePair: "对子",
// };

// 解析投注字符串
export function parseBetString(str){
    const result = [];
    const arr = str.split("|");
    for(let i=0;i<arr.length;i++){
        const arr2 = arr[i].split(",");
        result.push({
            id: parseInt(arr2[0]),
            value: arr2[1],
        });
    }
    return result;
}