import {Component} from 'react';
import {Checkbox, InputNumber} from "antd";

class MultipleExtra extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.data !== null,
            bet_amount: this.props.data !== null ? this.props.data.bet_amount : 0,
            multiple: this.props.data !== null ? this.props.data.multiple : 1,
        };
        this.getCurrentValue = this.getCurrentValue.bind(this);
        this.changeSelect = this.changeSelect.bind(this);
        this.changeBetAmount = this.changeBetAmount.bind(this);
        this.changeMultiple = this.changeMultiple.bind(this);
    }
    componentDidMount() {
    }
    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            selected: nextProps.data !== null,
            bet_amount: nextProps.data !== null ? nextProps.data.bet_amount : 0,
            multiple: nextProps.data !== null ? nextProps.data.multiple : 1,
        });
    }
    // 获取当前值
    getCurrentValue(){
        if(this.state.selected){
            return {
                bet_amount: this.state.bet_amount,
                multiple: this.state.multiple,
            }
        }else{
            return null;
        }
    }
    // 勾选框更新
    changeSelect(e){
        const flag = e.target.checked;
        this.setState({selected: flag}, ()=>{
            const val = this.getCurrentValue();
            this.props.onChange(val);
        });
    }
    // 更新金额
    changeBetAmount(v){
        this.setState({bet_amount: v}, ()=>{
            const val = this.getCurrentValue();
            this.props.onChange(val);
        });
    }
    // 更新赔率
    changeMultiple(v){
        this.setState({multiple: v}, ()=>{
            const val = this.getCurrentValue();
            this.props.onChange(val);
        });
    }
    render() {
        return (
            <div className="multiple-extra">
                <Checkbox checked={this.state.selected} onChange={this.changeSelect}/>
                投注超过<InputNumber size="small" value={this.state.bet_amount} controls={false} onBlur={this.props.onBlur} onChange={this.changeBetAmount}/>时, 赔付
                <InputNumber size="small" value={this.state.multiple} controls={false} onBlur={this.props.onBlur} onChange={this.changeMultiple}/>
            </div>
        );
    }
}

export default MultipleExtra;

