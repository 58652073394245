import React, {Component} from 'react';
import {Avatar} from "antd";
import {emitter, EVENTS} from "../util/event";
import {MenuKey} from "../constant/common";

class UserInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.data.user_id,
            avatar: this.props.data.avatar,
            nickname: this.props.data.nickname,
            remark: this.props.data.remark,
        };
        this.goToUserInfo = this.goToUserInfo.bind(this);
    }
    componentDidMount() {
    }
    // 跳转用户详情
    goToUserInfo(){
        const payload = {
            nickname: this.state.remark !== '' ? this.state.remark : this.state.nickname,
            user_id: this.state.id,
        };
        emitter.emit(EVENTS.SHOW_MENU, MenuKey.UserInfo.id, payload);
    }
    render() {
        return (
            <div className="user-info">
                <Avatar src={this.state.avatar} size={30}/>
                <span className="nickname" onClick={this.goToUserInfo}>{this.state.remark === "" ? this.state.nickname : this.state.remark}</span>

            </div>
        );
    }
}

export default UserInfo;

