import React, {Component} from 'react';
import {Card, Steps, Tag} from "antd";
import {emitter, EVENTS} from "../../../../common/util/event";
import dayjs from "dayjs";
import {tc} from "../../../../common/util/number";

class GameStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            game_no: 0, // 游戏期号
            status: 0, // 游戏状态
            open_at: 0, // 开盘时间
            close_at: 0, // 封盘时间
            draw_at: 0, // 开奖时间
            result_at: 0, // 结算时间
            draw_result: [], // 开奖结果
        };
        this.updateRoundInfo = this.updateRoundInfo.bind(this);
    }
    componentDidMount() {
        emitter.addListener(EVENTS.ROUND_INFO, this.updateRoundInfo);
    }
    componentWillUnmount() {
        emitter.removeListener(EVENTS.ROUND_INFO, this.updateRoundInfo);
    }
    // 更新
    updateRoundInfo(data){
        // 播放声音
        if(this.state.status !== 2 && data.status === 2){
            emitter.emit(EVENTS.RING_BELL, 3);
        }
        this.setState({
            status: data.status,
            open_at: data.open_at,
            close_at: data.close_at,
            draw_at: data.draw_at,
            result_at: data.result_at,
            game_no: data.game_no,
            draw_result: data.draw_result,
        });
    }
    render() {
        const steps = [
            {
                title: `开盘`,
                subTitle: `${this.state.open_at === 0 ? "" : dayjs.unix(this.state.open_at).format("HH:mm:ss")+', '}等待玩家下注`,
            },
            {
                title: `封盘`,
                subTitle: `${this.state.close_at === 0 ? "" : dayjs.unix(this.state.close_at).format("HH:mm:ss")+', '}停止接收玩家投注,等待开奖`,
            },
            {
                title: `游戏开奖`,
                subTitle: `${this.state.draw_at === 0 ? "" : dayjs.unix(this.state.draw_at).format("HH:mm:ss")+', '}正在获取三方开奖结果`,
            },
            {
                title: `结算`,
                subTitle: `${this.state.result_at === 0 ? "" : dayjs.unix(this.state.result_at).format("HH:mm:ss")+', '}结算信息展示, 稍后开始新的一期游戏`,
            },
        ];
        return (
            <Card className="game-status-card">
                <Tag className="tag-label" color="#4caf50">第{this.state.game_no}期游戏状态</Tag>
                <Steps
                    current={this.state.status-1}
                    size="small"
                    direction="vertical"
                    items={steps}
                />
                <div className="draw-result">
                    <div className="block-item"><span className="number-ball">{this.state.status < 4 ? '?' : this.state.draw_result[0]}</span></div>
                    <div className="block-item">+</div>
                    <div className="block-item"><span className="number-ball">{this.state.status < 4 ? '?' : this.state.draw_result[1]}</span></div>
                    <div className="block-item">+</div>
                    <div className="block-item"><span className="number-ball">{this.state.status < 4 ? '?' : this.state.draw_result[2]}</span></div>
                    <div className="block-item">=</div>
                    <div className="block-item"><span className="number-ball">{this.state.status < 4 ? '?' : tc(this.state.draw_result[3])}</span></div>
                </div>
            </Card>
        );
    }
}

export default GameStatus;

