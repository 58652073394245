import React,{Component} from 'react';
import {Modal} from "antd";
import {getBetNumName, parseBetString} from "../../../../common/constant/game28";

class MultipleModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnCount: 8, // 表格列数
        };
    }
    componentDidMount() {
    }
    render() {
        // 获取赔率
        const multipleArr = parseBetString(this.props.data.multiple);
        // 封装赔率表
        const rows = Math.ceil(multipleArr.length/this.state.columnCount);
        const tableArr = [];
        for(let i=0;i<rows;i++){
            tableArr.push([]);
        }
        for(let i=0;i<multipleArr.length;i=i+1){
            const index = parseInt(i/this.state.columnCount);
            tableArr[index].push(multipleArr[i]);
        }
        return (
            <Modal
                className="multiple-modal"
                open={true}
                onCancel={this.props.onCancel}
                title={`第${this.props.data.id}期赔率表`}
                maskClosable={false}
                footer={null}
            >
                <table className="multiple-table">
                    <tbody>
                    {
                        tableArr.map((item, i)=>{
                            return (
                                <React.Fragment key={i}>
                                    <tr className="bet-type">
                                        {
                                            item.map((sItem, ii)=>{
                                                return (
                                                    <td key={ii}>{getBetNumName(sItem.id)}</td>
                                                )
                                            })
                                        }
                                    </tr>
                                    <tr className="bet-multiple">
                                        {
                                            item.map((sItem, ii)=>{
                                                return (
                                                    <td key={ii}>{sItem.value}</td>
                                                )
                                            })
                                        }
                                    </tr>
                                </React.Fragment>
                            )
                        })
                    }
                    </tbody>
                </table>
            </Modal>
        );
    }
}

export default MultipleModal;

