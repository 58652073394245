import React, {Component} from 'react';
import './style.less';
import {emitter} from "../../../../common/util/event";
import {MenuKey} from "../../../../common/constant/common";
import {getRequest, postRequest} from "../../../../common/util/request";
import {Button, message, Spin, Table} from "antd";
import AddModal from "./addModal";

class SettingBetModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config_key: "bet_model_config", // 配置key
            loading: true,
            list: [], // 模式列表
            showAddModal: null,
            editIndex: -1,
        };
        this.updateConfig = this.updateConfig.bind(this);
        this.addBetModel = this.addBetModel.bind(this);
        this.getConfig = this.getConfig.bind(this);
        this.pageLoaded = this.pageLoaded.bind(this);
    }
    componentDidMount() {
        emitter.addListener(MenuKey.SettingBetModel.id, this.pageLoaded);
    }
    componentWillUnmount() {
        emitter.removeListener(MenuKey.SettingBetModel.id, this.pageLoaded);
    }
    // 页面加载
    pageLoaded(payload){
        this.getConfig();
    }
    // 获取配置
    getConfig(){
        getRequest("/admin/config/info", {key: this.state.config_key}, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                const obj = JSON.parse(data.data.value);
                obj.loading = false;
                this.setState(obj);
            }
        })
    }
    // 添加下注模式
    addBetModel(obj){
        let list = [...this.state.list];;
        if(this.state.editIndex === -1){
            list.push(obj);
            this.setState({list: list, showAddModal: null, editIndex: -1});
        }else{
            list[this.state.editIndex] = obj;
            this.setState({list: list, showAddModal: null, editIndex: -1});
        }
    }
    // 从列表中移除
    removeFromList(index){
        let list=[...this.state.list];
        list.splice(index, 1);
        this.setState({list: list});
    }
    // 更新游戏配置
    updateConfig(){
        const jsonStr = JSON.stringify({
            list: this.state.list,
        });
        postRequest("/admin/config/set", {key: this.state.config_key, value: jsonStr}, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                message.success("配置已更新");
            }
        })
    }
    render() {
        const columns = [
            {
                title: 'No',
                dataIndex: 'id',
                key: 'id',
                render: (text,d, i)=>{
                    return i+1;
                }
            },
            {
                title: '模式名',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '下注选项',
                dataIndex: 'bet_str',
                key: 'bet_str',
            },
            {
                title: '最小倍率',
                dataIndex: 'multiple_min',
                key: 'multiple_min',
            },
            {
                title: '最大倍率',
                dataIndex: 'multiple_max',
                key: 'multiple_max',
            },
            {
                title: '投注范围(元)',
                dataIndex: 'bet_amount',
                key: 'bet_amount',
                render: (text,d)=>{
                    return d.bet_amount*d.multiple_min + " ~ " + d.bet_amount*d.multiple_max;
                }
            },
            {
                title: '操作',
                dataIndex: 'extra',
                key: 'extra',
                render: (text,d, i)=>{
                    return (
                        <div className="btn-block">
                            <Button size="small" type="primary" className="btn-info" onClick={()=>this.setState({editIndex: i, showAddModal: d})}>编辑</Button>
                            <Button size="small" type="primary" className="btn-danger" onClick={()=>this.removeFromList(i)}>移除</Button>
                        </div>
                    )
                }
            },
        ];
        return (
            <div className="bet-model-setting-panel">
                <Spin tip="加载中...." spinning={this.state.loading}>
                    <div className="filter-block">
                        <Button type="primary" className="btn-success" onClick={()=>{this.setState({showAddModal: {}, editIndex: -1})}}>添加模式</Button>
                        <Button type="primary" className="btn-info" onClick={this.updateConfig}>保存配置</Button>
                    </div>
                    <Table bordered={true} rowKey={(record,i) => i} size="small" dataSource={this.state.list} columns={columns} pagination={false} className="bet-model-table"/>
                    {
                        this.state.showAddModal === null ? null :
                            <AddModal data={this.state.showAddModal} onCancel={()=>this.setState({editIndex: -1,showAddModal: null})} onOk={this.addBetModel}/>
                    }
                </Spin>
            </div>
        );
    }
}

export default SettingBetModel;

