import React, {Component} from 'react';
import './style.less';
import {emitter} from "../../../../common/util/event";
import {MenuKey} from "../../../../common/constant/common";
import RebateInput from "./rebateInput";
import {getUniqueID} from "../../../../common/util/number";
import {getRequest, postRequest} from "../../../../common/util/request";
import {Checkbox, Col, Divider, InputNumber, message, Row, Tag} from "antd";
import {MD5} from "../../../../common/util/crypto";

class SettingActivity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config_key: "activity_config", // 配置key
            rebate_loss: {
                bet_count_limit: {
                    checked: false,
                    count: 5,
                }, // 当日下注期数限制
                loss_list: [{
                    id: getUniqueID(),
                    amount:0,
                    percent:0
                }], // 计算方式列表
            }, // 亏损返利
            config_md5_key: '', // 配置唯一标识, 用来判断配置是否变化
        };
        this.addRebateInput = this.addRebateInput.bind(this);
        this.removeRebateInput = this.removeRebateInput.bind(this);
        this.getCurrentConfig = this.getCurrentConfig.bind(this);
        this.updateConfig = this.updateConfig.bind(this);
        this.changeObjectExtraAttr = this.changeObjectExtraAttr.bind(this);
        this.getConfig = this.getConfig.bind(this);
        this.pageLoaded = this.pageLoaded.bind(this);
        this.changeBetCountLimitChecked = this.changeBetCountLimitChecked.bind(this);
        this.changeBetCountLimitCount = this.changeBetCountLimitCount.bind(this);
    }
    componentDidMount() {
        emitter.addListener(MenuKey.SettingActivity.id, this.pageLoaded);
    }
    componentWillUnmount() {
        emitter.removeListener(MenuKey.SettingActivity.id, this.pageLoaded);
    }
    // 页面加载
    pageLoaded(payload){
        this.getConfig();
    }
    // 获取配置
    getConfig(){
        getRequest("/admin/config/info", {key: this.state.config_key}, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                const obj = JSON.parse(data.data.value);
                // 初始化组件id
                obj.rebate_loss.loss_list.map((item)=>{
                    item.id = getUniqueID();
                })
                this.setState(obj, ()=>{
                    this.setState({config_md5_key: MD5(JSON.stringify(this.getCurrentConfig()))});
                });
            }
        })
    }
    // 获取当前配置
    getCurrentConfig(){
        const obj = JSON.parse(JSON.stringify(this.state.rebate_loss));
        // 剔除无用属性
        for(let i=0;i<obj.loss_list.length;i++){
            delete obj.loss_list[i].id;
        }
        return {
            rebate_loss: obj,
        }
    }
    // 更新游戏配置
    updateConfig(){
        // 判断是否有改变, 无改变则不更新
        const newConfig = this.getCurrentConfig()
        const jsonStr = JSON.stringify(newConfig);
        const newConfigKey = MD5(jsonStr);
        if(newConfigKey !== this.state.config_md5_key){
            postRequest("/admin/config/set", {key: this.state.config_key, value: jsonStr}, (data)=>{
                if(data.code !== 0){
                    message.error(data.msg);
                }else{
                    message.success("配置已更新");
                    this.setState({config_md5_key: newConfigKey});
                }
            })
        }
    }
    // 添加更多组件
    addRebateInput(){
        const data = this.state.rebate_loss;
        data.loss_list.push({id: getUniqueID(),amount:0,percent:0});
        this.setState({rebate_loss: data});
    }
    // 移除组件
    removeRebateInput(index){
        const data = this.state.rebate_loss;
        data.loss_list.splice(index, 1);
        this.setState({rebate_loss: data});
    }
    // 更新计算方式属性
    changeObjectExtraAttr(v, attr, index){
        let list = this.state.rebate_loss.loss_list;
        list[index][attr] = v;
        const data = this.state.rebate_loss;
        data.loss_list = list;
        this.setState({rebate_loss: data});
    }
    // 更新下注期数限制
    changeBetCountLimitChecked(e){
        const data = this.state.rebate_loss;
        data.bet_count_limit.checked = e.target.checked;
        this.setState({rebate_loss: data}, ()=>{
            this.updateConfig();
        });
    }
    // 更新下注期数数量
    changeBetCountLimitCount(v){
        const data = this.state.rebate_loss;
        data.bet_count_limit.count = v;
        this.setState({rebate_loss: data}, ()=>{
            this.updateConfig();
        });
    }
    render() {
        return (
            <div className="activity-setting-panel">
                <Row>
                    <Col span={8} className="rule-block">
                        <Divider plain orientation="left"><Tag color="#ff5722">亏损返利</Tag></Divider>
                        <div className="input-box">
                            <label className="label">限制条件: </label>
                            <div className="form-box">
                                <Checkbox checked={this.state.rebate_loss.bet_count_limit.checked} onChange={this.changeBetCountLimitChecked}>
                                    当日下注不足<InputNumber size="small" min={1} max={136} value={this.state.rebate_loss.bet_count_limit.count} controls={false} onBlur={this.updateGameConfig} onChange={this.changeBetCountLimitCount}/>期, 不返利
                                </Checkbox>
                            </div>
                        </div>
                        <div className="input-box">
                            <label className="label">计算方式: </label>
                            <div className="form-box">
                                {
                                    this.state.rebate_loss.loss_list.map((item, i)=>{
                                        return (
                                            <RebateInput
                                                key={item.id}
                                                data={item}
                                                index={i}
                                                addAction={this.addRebateInput}
                                                removeAction={this.removeRebateInput}
                                                onBlur={this.updateConfig}
                                                onChange={(k,v)=>this.changeObjectExtraAttr(v, k, i)}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default SettingActivity;

