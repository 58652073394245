import React, {Component} from 'react';
import './style.less';
import {Table, message, Pagination, Button, Input, Tag, DatePicker, Row, Col, Modal} from "antd";
import {getRequest, postRequest} from "../../../../common/util/request";
import {formatYuan} from "../../../../common/util/number";
import {emitter} from "../../../../common/util/event";
import {datePresets, MenuKey} from "../../../../common/constant/common";
import {GiftOutlined, SearchOutlined} from "@ant-design/icons";
import UserInfo from "../../../../common/component/userInfo";
import dayjs from "dayjs";

class ActivityWageRebate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            limit: 15,
            list: [],
            total: 0,
            amount: 0,
            user_id: '',
            date: '',
        };
        this.getList = this.getList.bind(this);
        this.changePage = this.changePage.bind(this);
        this.pageLoaded = this.pageLoaded.bind(this);
        this.search = this.search.bind(this);
        this.changeDate = this.changeDate.bind(this);
        this.changeUser = this.changeUser.bind(this);
        this.startRebate = this.startRebate.bind(this);
    }
    componentDidMount() {
        emitter.addListener(MenuKey.ActivityWageRebate.id, this.pageLoaded);
    }
    componentWillUnmount() {
        emitter.removeListener(MenuKey.ActivityWageRebate.id, this.pageLoaded);
    }
    // 页面加载
    pageLoaded(payload){
        this.getList();
    }
    // 获取列表
    getList(){
        const query = {
            page: this.state.page,
            page_size: this.state.limit,
            user_id: this.state.user_id,
            date: this.state.date,
        };
        getRequest("/admin/activity/wage/rebate/list", query, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                this.setState({list: data.data.list, total: data.data.total, amount: data.data.amount});
            }
        })
    }
    // 更换页码
    changePage(page, pageSize){
        this.setState({limit: pageSize, page: page}, ()=>{
            this.getList();
        });
    }
    // 搜索
    search(){
        this.setState({page: 1}, ()=>{
            this.getList();
        })
    }
    // 更改日期
    changeDate(_,v){
        this.setState({date: v});
    }
    // 修改用户
    changeUser(e){
        const v = e.target.value;
        this.setState({user_id: v});
    }
    // 开始返利
    startRebate(){
        Modal.confirm({
            title: '一键流水返利',
            content: '执行后将计算今日参与游戏玩家的流水, 并将未领取的流水返利直接返还至用户余额上, 确定执行吗?',
            onOk: ()=>{
                postRequest("/admin/activity/wage/rebate", {}, (data)=>{
                    if(data.code !== 0){
                        message.error(data.msg);
                    }else{
                        message.success("执行成功, 执行结果可查看群内通知消息");
                        this.getList();
                    }
                })
            }
        });
    }
    render() {
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: '日期',
                dataIndex: 'date',
                key: 'date',
            },
            {
                title: '用户',
                dataIndex: 'user',
                key: 'user',
                render: (text,d)=>{
                    return (
                        <UserInfo data={text} />
                    )
                }
            },
            {
                title: '当日流水(元)',
                dataIndex: 'today_wage',
                key: 'today_wage',
                render: (text,d)=>{
                    return formatYuan(text)
                }
            },
            {
                title: '使用流水(元)',
                dataIndex: 'used_wage',
                key: 'used_wage',
                render: (text,d)=>{
                    return formatYuan(text)
                }
            },
            {
                title: '返利金额(元)',
                dataIndex: 'amount',
                key: 'amount',
                render: (text,d)=>{
                    return formatYuan(text)
                }
            },
            {
                title: '领取方式',
                dataIndex: 'auto',
                key: 'auto',
                render: (text,d)=>{
                    if(text){
                        return <Tag color="#8bc34a">自动</Tag>
                    }else{
                        return <Tag color="#03a9f4">手动</Tag>
                    }
                }
            },
            {
                title: '领取时间',
                dataIndex: 'created_at',
                key: 'created_at',
            },
        ];
        return (
            <div className="activity-wage-rebate-panel">
                <Row>
                    <Col span={18} className="filter-block">
                        <label>用户: </label>
                        <Input className="input-user" placeholder="用户ID" value={this.state.user_id} onChange={this.changeUser}/>
                        <label className="label">日期: </label>
                        <DatePicker presets={datePresets} value={this.state.date === '' ? null : dayjs(this.state.date, 'YYYY-MM-DD')} onChange={this.changeDate}/>
                        <Button type="primary" icon={<SearchOutlined />} onClick={this.search}>查询</Button>
                    </Col>
                    <Col span={6} className="right">
                        <Button type="primary" className="btn-success" icon={<GiftOutlined />} onClick={this.startRebate}>自动返利</Button>
                    </Col>
                </Row>
                <Table bordered={true} rowKey="id" size="small" dataSource={this.state.list} columns={columns} pagination={false} className="wage-rebate-table"/>
                <Pagination
                    size="small"
                    current={this.state.page}
                    total={this.state.total}
                    pageSize={this.state.limit}
                    onChange={this.changePage}
                    showSizeChanger={false}
                    showTotal={(total) => `共 ${total} 条数据, 合计 ${formatYuan(this.state.amount)} 元`}
                />
            </div>
        );
    }
}

export default ActivityWageRebate;

