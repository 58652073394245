import React, {Component} from 'react';
import './style.less';
import {Button, Input, message, Pagination, Select, Table, Tag} from "antd";
import {getRequest, host} from "../../../../common/util/request";
import {formatYuan} from "../../../../common/util/number";
import {ExportOutlined, SearchOutlined} from "@ant-design/icons";
import {emitter, EVENTS} from "../../../../common/util/event";
import {MenuKey} from "../../../../common/constant/common";
import UserInfo from "../../../../common/component/userInfo";

class UserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            limit: 15,
            list: [],
            total: 0,
            type: 0,
            keyword: '',
            sortKey: '',
        };
        this.changePage = this.changePage.bind(this);
        this.getUserList = this.getUserList.bind(this);
        this.changeType = this.changeType.bind(this);
        this.search = this.search.bind(this);
        this.handleTableChange = this.handleTableChange.bind(this);
        this.pageLoaded = this.pageLoaded.bind(this);
        this.export = this.export.bind(this);
        this.keywordRef = React.createRef();
    }
    componentDidMount() {
        emitter.addListener(MenuKey.UserList.id, this.pageLoaded);
    }
    componentWillUnmount() {
        emitter.removeListener(MenuKey.UserList.id, this.pageLoaded);
    }
    // 页面加载
    pageLoaded(payload){
        this.getUserList();
    }
    // 获取列表
    getUserList(){
        const query = {
            page: this.state.page,
            page_size: this.state.limit,
            type: this.state.type,
            keyword: this.state.keyword,
            sort_key: this.state.sortKey,
        };
        getRequest("/admin/user/list", query, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                this.setState({list: data.data.list, total: data.data.total});
            }
        })
    }
    // 导出
    export(){
        const url = host+`/admin/user/list/export?type=${this.state.type}&keyword=${this.state.keyword}&sort_key=${this.state.sortKey}&page=1&page_size=999999`;
        window.open(url);
    }
    // 更换页码
    changePage(page, pageSize){
        this.setState({limit: pageSize, page: page,}, ()=>{
            this.getUserList();
        });
    }
    // 更新查询类型
    changeType(v){
        this.setState({type: v});
    }
    // 搜索
    search(){
        const keyword = this.keywordRef.current.input.value;
        this.setState({page: 1, keyword: keyword}, ()=>{
            this.getUserList();
        })
    }
    // 排序
    handleTableChange(pagination, filters, sorter){
        let sortKey = '';
        if(sorter.order){
            sortKey = sorter.field;
        }
        this.setState({sortKey: sortKey},()=>{
            this.getUserList();
        });
    }
    // 跳转投注记录
    goToBetRecord(userId){
        const payload = {
            user_id: userId,
        };
        emitter.emit(EVENTS.SHOW_MENU, MenuKey.GameBetRecord.id, payload);
    }
    // 跳转输赢记录
    goToWinloseRecord(userId){
        const payload = {
            user_id: userId,
        };
        emitter.emit(EVENTS.SHOW_MENU, MenuKey.UserWinLose.id, payload);
    }
    render() {
        const columns = [
            {
                title: '昵称',
                dataIndex: 'user',
                key: 'user',
                render: (text,d)=>{
                    return (
                        <UserInfo data={text} />
                    )
                }
            },
            {
                title: '类型',
                dataIndex: 'type',
                key: 'type',
                render: (text,d)=>{
                    if(d.user.type === 1){
                        return (
                            <Tag color="#8bc34a">机器人</Tag>
                        )
                    }else if(d.user.type === 2){
                        return (
                            <Tag color="#607d8b">游戏托</Tag>
                        )
                    }else{
                        return (
                            <Tag color="#03a9f4">普通用户</Tag>
                        )
                    }
                }
            },
            {
                title: '余额(元)',
                dataIndex: 'points',
                key: 'points',
                sortDirections: ['descend'],
                sorter: true,
                render: (text,d)=>{
                    return formatYuan(text)
                }
            },
            {
                title: '充值金额(元)',
                dataIndex: 'deposit_amount',
                key: 'deposit_amount',
                sortDirections: ['descend'],
                sorter: true,
                render: (text,d)=>{
                    return text.toLocaleString()
                }
            },
            {
                title: '提现金额(元)',
                dataIndex: 'withdraw_amount',
                key: 'withdraw_amount',
                sortDirections: ['descend'],
                sorter: true,
                render: (text,d)=>{
                    return text.toLocaleString()
                }
            },
            {
                title: '流水(元)',
                dataIndex: 'wage',
                key: 'wage',
                sortDirections: ['descend'],
                sorter: true,
                render: (text,d)=>{
                    return formatYuan(text)
                }
            },
            {
                title: '流水返利点位',
                dataIndex: 'wage_rebate_rate',
                key: 'wage_rebate_rate',
                sortDirections: ['descend'],
                sorter: true,
                render: (text,d)=>{
                    return text/10+"%"
                }
            },
            {
                title: '操作',
                dataIndex: 'extra',
                key: 'extra',
                render: (text,d)=>{
                    return (
                        <div className="btn-block">
                            <Button size="small" type="primary" onClick={()=>this.goToWinloseRecord(d.user.user_id)}>游戏输赢</Button>
                            <Button size="small" className="btn-warning" onClick={()=>this.goToBetRecord(d.user.user_id)}>投注记录</Button>
                        </div>
                    )
                }
            },
        ];
        return (
            <div className="user-list-panel">
                <div className="filter-block">
                    <Select className="input-select-type" value={this.state.type} onChange={this.changeType}>
                        <Select.Option value={0}>请选择类型</Select.Option>
                        <Select.Option value={1}>机器人</Select.Option>
                        <Select.Option value={2}>游戏托</Select.Option>
                        <Select.Option value={3}>普通玩家</Select.Option>
                    </Select>
                    <Input ref={this.keywordRef} className="input-keyword" placeholder="支持昵称/备注/用户ID查询"/>
                    <Button type="primary" icon={<SearchOutlined />} onClick={this.search}>查询</Button>
                    <Button type="primary" className="btn-warning" icon={<ExportOutlined />} onClick={this.export}>导出</Button>
                </div>
                <Table bordered={true} rowKey="id" size="small" dataSource={this.state.list} columns={columns} pagination={false} className="user-list-table" onChange={this.handleTableChange}/>
                <Pagination
                    size="small"
                    current={this.state.page}
                    total={this.state.total}
                    pageSize={this.state.limit}
                    onChange={this.changePage}
                    showSizeChanger={false}
                    showTotal={(total) => `共 ${total} 条数据`}
                />
            </div>
        );
    }
}

export default UserList;

