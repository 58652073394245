import React, {Component} from 'react';
import {Card, Table, Tag} from "antd";
import UserInfo from "../../../../common/component/userInfo";
import {emitter, EVENTS} from "../../../../common/util/event";
import {formatYuan} from "../../../../common/util/number";
import dayjs from "dayjs";
import {getBetNumName, parseBetString} from "../../../../common/constant/game28";

class GamePlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            status: 0,
        };
        this.updateRoundInfo = this.updateRoundInfo.bind(this);
    }
    componentDidMount() {
        emitter.addListener(EVENTS.ROUND_INFO, this.updateRoundInfo);
    }
    componentWillUnmount() {
        emitter.removeListener(EVENTS.ROUND_INFO, this.updateRoundInfo);
    }
    // 更新
    updateRoundInfo(data){
        this.setState({
            list: data.bet_list,
            status: data.status,
        });
    }
    render() {
        const columns = [
            {
                title: '用户',
                dataIndex: 'user',
                key: 'user',
                // filters: [
                //     {
                //         text: '真人',
                //         value: 3,
                //     },
                //     {
                //         text: '假人',
                //         value: 2,
                //     },
                // ],
                // onFilter: (value, record) => {
                //     return record.user.type === value
                // },
                render: (text,d)=>{
                    return (
                        <UserInfo data={text} />
                    )
                }
            },
            {
                title: '下注时间',
                dataIndex: 'bet_at',
                key: 'bet_at',
                sorter: (a, b) => a.bet_at - b.bet_at,
                render: (text,d)=>{
                    return dayjs.unix(text).format('HH:mm:ss')
                }
            },
            {
                title: '下注总额(元)',
                dataIndex: 'bet_amount',
                key: 'bet_amount',
                sorter: (a, b) => a.bet_amount - b.bet_amount,
                render: (text,d)=>{
                    return formatYuan(text)
                }
            },
            {
                title: '下注内容',
                dataIndex: 'bet_str',
                key: 'bet_str',
                render: (text,d)=>{
                    const arr = parseBetString(text);
                    return (
                        <table className="user-bet-table">
                            <tbody>
                                <tr>
                                    <td className="bet-name">选项</td>
                                    {
                                        arr.map((item, i)=>{
                                            return (
                                                <td key={i} className="bet-name">{getBetNumName(item.id)}</td>
                                            )
                                        })
                                    }
                                </tr>
                                <tr>
                                    <td>下注</td>
                                    {
                                        arr.map((item, i)=>{
                                            return (
                                                <td key={i}>{formatYuan(item.value)}</td>
                                            )
                                        })
                                    }
                                </tr>
                            </tbody>
                        </table>
                    )
                }
            },
            {
                title: '本轮输赢',
                dataIndex: 'winlose',
                key: 'winlose',
                render: (text,d)=>{
                    if(this.state.status<4){
                        return '-'
                    }else{
                        const amount = d.winlose-d.bet_amount;
                        return <span className={amount > 0 ? "number-win" : amount === 0 ? "" : "number-lose"}>{formatYuan(amount)}</span>
                    }
                }
            },
        ];
        return (
            <Card className="player-list-card">
                <Tag className="tag-label" color="#00bcd4">正在投注用户</Tag>
                <Table
                    bordered={true}
                    dataSource={this.state.list}
                    rowKey={(record) => record.user.user_id}
                    columns={columns}
                    size="small"
                    pagination={false}
                    className="player-bet-table"
                />
            </Card>
        );
    }
}

export default GamePlayer;

