import {message} from 'antd';
import {MD5} from './crypto';
import {getToken} from "./config";
import {TokenExpireErrCode} from "../constant/common";

// api地址
// export const host = "http://192.168.31.157:8202";
export const host = "http://13.212.144.69";
// post请求
export function postRequest(url, data, successFunc) {
    url = host + url;
    // 加上默认参数
    data["timestamp"] = parseInt(Date.parse(new Date()) / 1000) + "";
    data["sign"] = getSign(data);
    let token = getToken();
    fetch(url, {
        credentials: "omit",
        method: "post",
        headers: {
            'Accept': 'application/json; charset=utf-8',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': token == null ? "" : token,
        },
        body: JSON.stringify(data)
    }).then(function(response) {
        if (response.status <= 400) {
            response.json().then(function(data) {
                // 未登陆检测
                if(data.code === TokenExpireErrCode){
                    window.location.href = "/login";
                    return;
                }
                successFunc(data);
            });
        } else {
            let data = {
                code: -1,
                msg: "非法请求, 请求失败!"
            };
            successFunc(data);
        }
    }).catch(function(err) {
        message.error("服务器错误");
    });
}

// get请求
export function getRequest(url, data, successFunc){
    // 加上默认参数
    data["timestamp"] = parseInt(Date.parse(new Date()) / 1000) + "";
    data["sign"] = getSign(data);
    url = host + url + "?" + getParams(data);
    let token = getToken();
    fetch(url, {
        credentials: "omit",
        method: "get",
        headers: {
            'Accept': 'application/json; charset=utf-8',
            'Authorization': token == null ? "" : token,
        }
    }).then(function(response) {
        if (response.status <= 400) {
            response.json().then(function(data) {
                // 未登陆检测
                if(data.code === TokenExpireErrCode){
                    window.location.href = "/login";
                    return;
                }
                successFunc(data)
            });
        } else {
            let data = {
                code: -1,
                msg: "非法请求, 请求失败!"
            };
            successFunc(data);
        }
    }).catch(function(err) {
        message.error("服务器错误");
    });
}

// 计算签名
function getSign(data){
    let keyArr = Object.keys(data).sort();
    let str = "";
    for(let i=0;i<keyArr.length;i++){
        str = str + keyArr[i] + "=" + data[keyArr[i]];
    }
    str = "Scum" + str + "Virus";
    return MD5(str);
}

function getParams(obj) {
    let params = "";
    for (let k in obj) {
        params = params + k + "=" + obj[k] + "&";
    }
    if (params.length !== 0) {
        params = params.substr(0, params.length - 1);
    }
    return params;
}
