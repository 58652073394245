import React,{Component} from 'react';
import {Button, Input, message, Modal} from "antd";
import {postRequest} from "../../../../common/util/request";

class AddModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            account: '',
            password: '',
            suid: '',
        };
        this.checkBot = this.checkBot.bind(this);
        this.addBot = this.addBot.bind(this);
        this.accountRef = React.createRef();
        this.passwordRef = React.createRef();
    }
    componentDidMount() {
    }
    // 校验机器人
    checkBot(){
        const account = this.accountRef.current.input.value;
        const password = this.passwordRef.current.input.value;
        if(account === ""){
            message.warning("请输入账号");
            return;
        }
        if(password === ""){
            message.warning("请输入密码");
            return;
        }
        postRequest("/admin/user/bot/check",{account: account,password: password}, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                message.success("校验账号成功");
                this.setState({account: account,password: password,suid: data.data.suid});
            }
        });
    }
    // 添加机器人
    addBot(){
        if(this.state.suid === ''){
            message.warning("请先校验账号!");
            return;
        }
        postRequest("/admin/user/bot/add",{account: this.state.account,password: this.state.password,suid:this.state.suid}, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                message.success("添加账号成功");
                this.props.onOk();
            }
        });
    }
    render() {
        return (
            <Modal
                className="add-bot-modal"
                open={true}
                onCancel={this.props.onCancel}
                title="添加机器人"
                maskClosable={false}
                onOk={this.addBot}
            >
                <div className="form-div">
                    <label>账号:</label>
                    <Input placeholder="请输入SuApp用户账号" ref={this.accountRef}/>
                </div>
                <div className="form-div">
                    <label>密码:</label>
                    <Input placeholder="请输入SuApp用户密码" ref={this.passwordRef}/>
                </div>
                <div className="form-div">
                    <label>ID:</label>
                    <Input value={this.state.suid} disabled placeholder="点击右侧按钮获取"/>
                    <Button type="primary" onClick={this.checkBot}>校验</Button>
                </div>
            </Modal>
        );
    }
}

export default AddModal;

