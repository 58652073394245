import React, {Component} from 'react';
import './style.less';
import {emitter} from "../../../../common/util/event";
import {MenuKey, rangePresets} from "../../../../common/constant/common";
import {getRequest, host, postRequest} from "../../../../common/util/request";
import {Button, DatePicker, Input, message, Modal, Pagination, Select, Table, Tag} from "antd";
import {SearchOutlined, ExportOutlined} from "@ant-design/icons";
import UserInfo from "../../../../common/component/userInfo";

class PaymentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 0,
            page: 1,
            limit: 12,
            list: [],
            total: 0,
            amount: 0,
            user_id: '',
            status: -1,
            start_at: null,
            end_at: null,
        };
        this.pageLoaded = this.pageLoaded.bind(this);
        this.getPaymentList = this.getPaymentList.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.changeUser = this.changeUser.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.changePage = this.changePage.bind(this);
        this.cancel = this.cancel.bind(this);
        this.audit = this.audit.bind(this);
        this.changeType = this.changeType.bind(this);
        this.export = this.export.bind(this);
    }
    componentDidMount() {
        emitter.addListener(MenuKey.PaymentList.id, this.pageLoaded);
    }
    componentWillUnmount() {
        emitter.removeListener(MenuKey.PaymentList.id, this.pageLoaded);
    }
    // 页面加载
    pageLoaded(payload){
        this.setState({type: payload && payload.type ? payload.type : 0,user_id: payload && payload.user_id ? payload.user_id : '',status: payload && payload.status ? payload.status : -1, start_at: null, end_at: null, page: 1}, ()=>{
            this.getPaymentList();
        })
    }
    // 获取列表
    getPaymentList(){
        const query = {
            type: this.state.type,
            user_id: this.state.user_id,
            status: this.state.status,
            start_at: this.state.start_at !== null ? this.state.start_at.format('YYYY-MM-DD') : "",
            end_at: this.state.end_at !== null ? this.state.end_at.format('YYYY-MM-DD') : "",
            page: this.state.page,
            page_size: this.state.limit,
        };
        getRequest("/admin/payment/list", query, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                this.setState({list: data.data.list, total: data.data.total, amount: data.data.amount});
            }
        })
    }
    // 导出
    export(){
        const url = host+`/admin/payment/list/export?type=${this.state.type}&user_id=${this.state.user_id}&status=${this.state.status}&start_at=${this.state.start_at !== null ? this.state.start_at.format('YYYY-MM-DD') : ""}&end_at=${this.state.end_at !== null ? this.state.end_at.format('YYYY-MM-DD') : ""}&page=1&page_size=999999`;
        window.open(url);
    }
    // 更改日期
    onChangeDate(value){
        if(value === null){
            this.setState({start_at: null, end_at: null});
        }else{
            this.setState({start_at: value[0], end_at: value[1]});
        }
    }
    // 修改用户
    changeUser(e){
        const v = e.target.value;
        this.setState({user_id: v});
    }
    // 修改状态
    changeStatus(v){
        this.setState({status: v});
    }
    // 修改类型
    changeType(v){
        this.setState({type: v});
    }
    // 更换页码
    changePage(page, pageSize){
        this.setState({limit: pageSize, page: page}, ()=>{
            this.getPaymentList();
        });
    }
    // 撤回
    cancel(id){
        Modal.confirm({
           title: '撤回充值',
           content: '确定要撤回这笔订单吗？成功后用户余额将被扣除',
           onOk: ()=>{
                postRequest("/admin/payment/cancel", {id: id}, (data)=>{
                    if(data.code !== 0){
                        message.error(data.msg);
                    }else{
                        message.success("撤回成功");
                        this.getPaymentList();
                    }
                });
           }
        });
    }
    // 审核
    audit(id, flag){
        postRequest("/admin/payment/audit", {id: id, state: flag}, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                message.success("审核成功");
                this.getPaymentList();
            }
        });
    }
    render() {
        const columns = [
            {
                title: '订单ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: '用户',
                dataIndex: 'user',
                key: 'user',
                render: (text,d)=>{
                    return (
                        <UserInfo data={text} />
                    )
                }
            },
            {
                title: '充提金额(元)',
                dataIndex: 'amount',
                key: 'amount',
                render: (text,d)=>{
                    if(d.type === 1){
                        return <span className="number-win">{text.toLocaleString()}</span>
                    }else{
                        return <span className="number-lose">-{text.toLocaleString()}</span>
                    }
                }
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (text,d)=>{
                    if(text === 0) {
                        return <Tag color="#00bcd4">待审核</Tag>
                    }else if(text === 1){
                        return <Tag color="#8bc34a">已完成</Tag>
                    }else if(text === 2){
                        return <Tag color="#9e9e9e">已撤回</Tag>
                    }else{
                        return <Tag color="#f44336">已拒绝</Tag>
                    }
                }
            },
            {
                title: '时间',
                dataIndex: 'created_at',
                key: 'created_at',
            },
            {
                title: '操作',
                dataIndex: 'extra',
                key: 'extra',
                render: (text,d)=>{
                    let btns = null;
                    if(d.status === 0){
                        btns = (
                            <>
                                <Button size="small" className="btn-success" onClick={()=>this.audit(d.id, true)}>通过</Button>
                                <Button size="small" className="btn-warning" onClick={()=>this.audit(d.id, false)}>拒绝</Button>
                            </>
                        )
                    }else if(d.status === 1){
                        btns = (
                            <Button size="small" className="btn-danger" onClick={()=>this.cancel(d.id)}>撤回</Button>
                        )
                    }
                    return (
                        <div className="btn-block">
                            {btns}
                        </div>
                    )
                }
            },
        ];
        let totalStr = null;
        if(this.state.type === 0){
            totalStr = (total)=>{
                return `共 ${this.state.total} 条数据`;
            }
        }else{
            totalStr = (total)=>{
                return `共 ${this.state.total} 条数据, 合计 ${this.state.amount.toLocaleString()} 元`;
            }
        }
        return (
            <div className="payment-list-panel">
                <div className="filter-block">
                    <label>用户: </label>
                    <Input className="input-user" placeholder="用户ID" value={this.state.user_id} onChange={this.changeUser}/>
                    <label className="label">类型: </label>
                    <Select value={this.state.type} onChange={this.changeType}>
                        <Select.Option value={0}>全部</Select.Option>
                        <Select.Option value={1}>充值</Select.Option>
                        <Select.Option value={2}>提现</Select.Option>
                    </Select>
                    <label className="label">状态: </label>
                    <Select value={this.state.status} onChange={this.changeStatus}>
                        <Select.Option value={-1}>全部</Select.Option>
                        <Select.Option value={0}>待审核</Select.Option>
                        <Select.Option value={1}>成功</Select.Option>
                        <Select.Option value={3}>拒绝</Select.Option>
                        <Select.Option value={2}>已撤回</Select.Option>
                    </Select>
                    <label className="label">时间: </label>
                    <DatePicker.RangePicker presets={rangePresets} value={[this.state.start_at,this.state.end_at]} onChange={this.onChangeDate}/>
                    <Button type="primary" icon={<SearchOutlined />} onClick={()=>this.changePage(1,this.state.limit)}>查询</Button>
                    <Button type="primary" className="btn-warning" icon={<ExportOutlined />} onClick={this.export}>导出</Button>
                </div>
                <Table
                    bordered={true}
                    rowKey="id"
                    size="small"
                    dataSource={this.state.list}
                    columns={columns}
                    pagination={false}
                    className="payment-list-table"
                />
                <Pagination
                    size="small"
                    current={this.state.page}
                    total={this.state.total}
                    pageSize={this.state.limit}
                    onChange={this.changePage}
                    showSizeChanger={false}
                    showTotal={totalStr}
                />
            </div>
        );
    }
}

export default PaymentList;

