import React, {Component} from 'react';
import {Button, message, Spin, Table} from "antd";
import AddAdModal from "./addAdModal";
import "./style.less";
import {emitter} from "../../../../common/util/event";
import {MenuKey} from "../../../../common/constant/common";
import {getRequest, postRequest} from "../../../../common/util/request";

class AdvertisementTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config_key: "advertisement_config", // 配置key
            loading: true,
            list: [],
            editIndex: -1,
            showAddModal: null,
        };
        this.remove = this.remove.bind(this);
        this.add = this.add.bind(this);
        this.getConfig = this.getConfig.bind(this);
        this.pageLoaded = this.pageLoaded.bind(this);
        this.updateConfig = this.updateConfig.bind(this);
    }
    componentDidMount() {
        emitter.addListener(MenuKey.SettingAdvertisement.id, this.pageLoaded);
    }
    componentWillUnmount() {
        emitter.removeListener(MenuKey.SettingAdvertisement.id, this.pageLoaded);
    }
    // 页面加载
    pageLoaded(payload){
        this.getConfig();
    }
    // 获取配置
    getConfig(){
        getRequest("/admin/config/info", {key: this.state.config_key}, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                const obj = JSON.parse(data.data.value);
                obj.loading = false;
                this.setState(obj);
            }
        })
    }
    // 移除
    remove(i){
        let list = [...this.state.list];
        list.splice(i, 1);
        this.setState({list: list});
    }
    // 添加
    add(obj){
        let list = [...this.state.list];;
        if(this.state.editIndex === -1){
            list.push(obj);
            this.setState({list: list, showAddModal: null, editIndex: -1});
        }else{
            list[this.state.editIndex] = obj;
            this.setState({list: list, showAddModal: null, editIndex: -1});
        }
    }
    // 更新游戏配置
    updateConfig(){
        const jsonStr = JSON.stringify({
            list: this.state.list,
        });
        postRequest("/admin/config/set", {key: this.state.config_key, value: jsonStr}, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                message.success("配置已更新");
            }
        })
    }
    render() {
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: '发送间隔周期(秒)',
                dataIndex: 'duration',
                key: 'duration',
            },
            {
                title: '发送内容',
                dataIndex: 'content',
                key: 'content',
            },
            {
                title: '操作',
                dataIndex: 'extra',
                key: 'extra',
                render: (text,d, i)=>{
                    return (
                        <div className="btn-block">
                            <Button size="small" type="primary" className="btn-info" onClick={()=>this.setState({editIndex: i, showAddModal: d})}>编辑</Button>
                            <Button size="small" type="primary" className="btn-danger" onClick={()=>this.remove(i)}>移除</Button>
                        </div>
                    )
                }
            },
        ];
        return (
            <div className="ad-setting-panel">
                <Spin tip="加载中...." spinning={this.state.loading}>
                    <div className="btn-group">
                        <Button type="primary" onClick={()=>{this.setState({showAddModal: {}, editIndex: -1})}}>添加广告</Button>
                        <Button type="primary" className="btn-success" onClick={this.updateConfig}>保存配置</Button>
                    </div>
                    <Table bordered={true} rowKey="id" size="small" dataSource={this.state.list} columns={columns} pagination={false}/>
                    {
                        this.state.showAddModal === null ? null :
                            <AddAdModal data={this.state.showAddModal} onCancel={()=>this.setState({editIndex: -1,showAddModal: null})} onOk={this.add}/>
                    }
                </Spin>
            </div>
        );
    }
}

export default AdvertisementTable;

