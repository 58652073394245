import React, {Component} from 'react';
import {Col, Divider, Input, Tag} from "antd";

class MessageCommand extends Component {
    constructor(props) {
        super(props);
        this.state = {
            1: '1', // 查询信息
            2: '2', // 查询当日流水
            3: '流水', // 获取当日流水返利
            4: '取消', // 取消投注
            5: '3', // 查历史
        };
        this.updateAttr = this.updateAttr.bind(this);
        this.getValue = this.getValue.bind(this);
    }
    componentDidMount() {
    }
    componentWillReceiveProps(nextProps, nextContext) {
        this.setState(nextProps.data);
    }
    // 更新指令
    updateAttr(v,attr){
        this.props.onUpdate(v,"command",attr);
    }
    // 获取值
    getValue(){
        return this.state;
    }
    render() {
        return (
            <Col span={8} className="rule-block command-block">
                <Divider plain orientation="left"><Tag color="#ff5722">消息指令</Tag></Divider>
                <div className="input-box">
                    <label className="label">查积分: </label>
                    <div className="form-box">
                        <Input size="small" value={this.state[1]} placeholder="为空时不支持该功能" onChange={(v)=>this.updateAttr(v.target.value, "1")}/>
                    </div>
                </div>
                <div className="input-box">
                    <label className="label">查流水: </label>
                    <div className="form-box">
                        <Input size="small" value={this.state[2]} placeholder="为空时不支持该功能" onChange={(v)=>this.updateAttr(v.target.value, "2")}/>
                    </div>
                </div>
                <div className="input-box">
                    <label className="label">查历史: </label>
                    <div className="form-box">
                        <Input size="small" value={this.state[5]} placeholder="为空时不支持该功能" onChange={(v)=>this.updateAttr(v.target.value, "5")}/>
                    </div>
                </div>
                <div className="input-box">
                    <label className="label">获取流水返利: </label>
                    <div className="form-box">
                        <Input size="small" value={this.state[3]} placeholder="为空时不支持该功能" onChange={(v)=>this.updateAttr(v.target.value, "3")}/>
                    </div>
                </div>
                <div className="input-box">
                    <label className="label">取消投注: </label>
                    <div className="form-box">
                        <Input size="small" value={this.state[4]} placeholder="为空时不支持该功能" onChange={(v)=>this.updateAttr(v.target.value, "4")}/>
                    </div>
                </div>
            </Col>
        );
    }
}

export default MessageCommand;

