import React, {Component} from 'react';
import {Checkbox, Col, Divider, Tag} from "antd";

class MessageRemind extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bet_success: false, // 下注成功提醒
            bet_failed: false, // 下注失败提醒
            bet_wrong_format: false, // 下注格式错误提醒
            before_close: false, // 封盘前60秒提醒
        };
        this.updateAttr = this.updateAttr.bind(this);
        this.getValue = this.getValue.bind(this);
    }
    componentDidMount() {
    }
    componentWillReceiveProps(nextProps, nextContext) {
        this.setState(nextProps.data);
    }
    // 更新指令
    updateAttr(v,attr){
        this.props.onUpdate(v,"remind",attr);
    }
    // 获取值
    getValue(){
        return this.state;
    }
    render() {
        return (
            <Col span={8} className="rule-block remind-block">
                <Divider plain orientation="left"><Tag color="#ff5722">消息提醒</Tag></Divider>
                <div className="input-box">
                    <label className="label">下注成功提醒: </label>
                    <div className="form-box">
                        <Checkbox checked={this.state.bet_success} onChange={(e)=>{this.setState({bet_success: e.target.checked},()=>{this.props.onUpdate(this.state.bet_success,"remind","bet_success");});}}/>
                    </div>
                </div>
                <div className="input-box">
                    <label className="label">下注失败提醒: </label>
                    <div className="form-box">
                        <Checkbox checked={this.state.bet_failed} onChange={(e)=>{this.setState({bet_failed: e.target.checked},()=>{this.props.onUpdate(this.state.bet_failed,"remind","bet_failed");});}}/>
                    </div>
                </div>
                <div className="input-box">
                    <label className="label">下注格式错误提醒: </label>
                    <div className="form-box">
                        <Checkbox checked={this.state.bet_wrong_format} onChange={(e)=>{this.setState({bet_wrong_format: e.target.checked},()=>{this.props.onUpdate(this.state.bet_wrong_format,"remind","bet_wrong_format");});}}/>
                    </div>
                </div>
                <div className="input-box">
                    <label className="label">封盘前60秒提醒: </label>
                    <div className="form-box">
                        <Checkbox checked={this.state.before_close} onChange={(e)=>{this.setState({before_close: e.target.checked},()=>{this.props.onUpdate(this.state.bet_wrong_format,"remind","bet_wrong_format");});}}/>
                    </div>
                </div>
            </Col>
        );
    }
}

export default MessageRemind;

