import React, {Component} from 'react';
import './style.less';
import {getRequest} from "../../common/util/request";
import {message} from "antd";

class BetResult extends Component {
    constructor(props) {
        super(props);
        const search = window.location.search; // 获取 URL 中的查询字符串，如 "?foo=bar"
        const params = new URLSearchParams(search); // 使用 URLSearchParams 解析查询字符串
        // 获取参数
        const gameNo = params.get('game_no');
        this.state = {
            game_no: gameNo,
            list: [],
            loading: true,
        };
        this.getBetResult = this.getBetResult.bind(this);
    }
    componentDidMount() {
        this.getBetResult();
    }
    // 获取投注结果
    getBetResult(){
        getRequest("/admin/game/current/bet/result", {game_no: this.state.game_no}, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                this.setState({list: data.data, loading: false});
            }
        })
    }
    render() {
        return (
            <div id="bet-table" className="game-bet-result">
                <div className="header">
                    水云天加拿大第{this.state.game_no}期游戏投注结果
                </div>
                <table className="bet-table">
                    <tbody>
                        <tr className="table-header">
                            <td>序号</td>
                            <td>用户</td>
                            <td>投注金额</td>
                            <td>投注内容</td>
                            <td>余额</td>
                        </tr>
                        {
                            this.state.list.map((item, i)=>{
                                return (
                                    <tr key={i}>
                                        <td>{i+1}</td>
                                        <td>{item.user.nickname}</td>
                                        <td>{item.bet_amount}</td>
                                        <td>{item.bet_info}</td>
                                        <td>{item.points}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {
                    this.state.loading ? null :
                        <div id="over"></div>
                }
            </div>
        );
    }
}

export default BetResult;

