import React, {Component} from 'react';
import {Button, Col, Divider, Tag} from "antd";
import {getUniqueID} from "../../../../common/util/number";
import AutoReplyComponent from "./autoReplyComponent";

class MessageAutoReply extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: []
        };
        this.updateAttr = this.updateAttr.bind(this);
        this.getValue = this.getValue.bind(this);
        this.addComponent = this.addComponent.bind(this);
        this.removeComponent = this.removeComponent.bind(this);
        this.updateComponentValue = this.updateComponentValue.bind(this);
        this.initData = this.initData.bind(this);
    }
    componentDidMount() {
    }
    // 初始化数据
    initData(list){
        for(let i=0;i<list.length;i++){
            list[i].id = getUniqueID();
        }
        this.setState({list: list});
    }
    // 更新指令
    updateAttr(v,attr){
        this.props.onUpdate(v,"auto_reply",attr);
    }
    // 获取值
    getValue(){
        let list = this.state.list;
        let newList = [];
        for(let i=0;i<list.length;i++){
            if(list[i].content !== '' && list[i].reply !== ''){
                newList.push({content: list[i].content, reply: list[i].reply});
            }
        }
        return newList;
    }
    // 添加组件
    addComponent(){
        let obj = {
            id: getUniqueID(),
            content: '',
            reply: '',
        }
        let list = this.state.list;
        list.push(obj);
        this.setState({list: list});
    }
    // 移除组件
    removeComponent(id){
        let list = this.state.list;
        for(let i=0;i<list.length;i++){
            if(list[i].id === id){
                list.splice(i, 1);
                break;
            }
        }
        this.setState({list: list}, ()=>{
            this.props.onBlur();
        });
    }
    // 更新组件值
    updateComponentValue(id, content, reply){
        let list = this.state.list;
        for(let i=0;i<list.length;i++){
            if(list[i].id === id){
                list[i].content = content;
                list[i].reply = reply;
                break;
            }
        }
        this.setState({list: list});
    }
    render() {
        return (
            <Col span={8} className="rule-block reply-block">
                <Divider plain orientation="left"><Tag color="#ff5722">自动回复</Tag></Divider>
                {
                    this.state.list.map((item, i)=>{
                        return <AutoReplyComponent key={item.id} onRemove={this.removeComponent} onUpdate={this.updateComponentValue} data={item}/>
                    })
                }
                <Button type="primary" block={true} onClick={this.addComponent}>添加记录</Button>
            </Col>
        );
    }
}

export default MessageAutoReply;

