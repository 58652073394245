import React, {Component} from 'react';
import {Col, Divider, InputNumber, Radio, Tag} from "antd";

class BetCommon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bet_close_duration: 30, // 封盘时间(秒)
            result_show_duration: 5, // 结算显示时间(秒)
            time_system: 2, // 时令
        };
    }
    componentDidMount() {
    }
    componentWillReceiveProps(nextProps, nextContext) {
        this.setState(nextProps.data);
    }
    // 更新指令
    updateAttr(v,attr){
        this.props.onUpdate(v,"common",attr);
    }
    // 获取值
    getValue(){
        return this.state;
    }
    render() {
        return (
            <Col span={8} className="rule-block">
                <Divider plain orientation="left"><Tag color="#ff5722">通用配置</Tag></Divider>
                <div className="input-box">
                    <label className="label">时令: </label>
                    <div className="form-box">
                        <Radio.Group value={this.state.time_system} onChange={(e)=>this.updateAttr(e.target.value, "time_system")}>
                            <Radio value={1}>夏令时</Radio>
                            <Radio value={2}>冬令时</Radio>
                        </Radio.Group>
                    </div>
                </div>
                <div className="input-box">
                    <label className="label">封盘时间: </label>
                    <div className="form-box">
                        <InputNumber size="small" value={this.state.bet_close_duration} min={30} max={180} controls={false} onChange={(v)=>this.updateAttr(v, "bet_close_duration")}/>秒
                    </div>
                </div>
                <div className="input-box">
                    <label className="label">结算时间: </label>
                    <div className="form-box">
                        <InputNumber size="small" value={this.state.result_show_duration} min={0} max={30} controls={false} onChange={(v)=>this.updateAttr(v, "result_show_duration")}/>秒
                    </div>
                </div>
            </Col>
        );
    }
}

export default BetCommon;

