import React, {Component} from 'react';
import './style.less';
import {emitter} from "../../../../common/util/event";
import {MenuKey} from "../../../../common/constant/common";
import {getRequest, postRequest} from "../../../../common/util/request";
import {Button, message, Row, Spin} from "antd";
import MessageCommand from "./command";
import MessagePrompt from "./prompt";
import MessageAutoReply from "./autoReply";
import MessageRemind from "./remind";

class SettingMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config_key: "message_config", // 配置key
            loading: true,
            command: {}, // 消息指令
            prompt: {}, // 消息提示
            auto_reply: [], // 自动回复
            remind: {}, // 消息提醒
        };
        this.getCurrentConfig = this.getCurrentConfig.bind(this);
        this.updateConfig = this.updateConfig.bind(this);
        this.getConfig = this.getConfig.bind(this);
        this.pageLoaded = this.pageLoaded.bind(this);
        this.updateAttr = this.updateAttr.bind(this);
        this.commandRef = React.createRef();
        this.promptRef = React.createRef();
        this.replyRef = React.createRef();
        this.remindRef = React.createRef();
    }
    componentDidMount() {
        emitter.addListener(MenuKey.SettingMessage.id, this.pageLoaded);
    }
    componentWillUnmount() {
        emitter.removeListener(MenuKey.SettingMessage.id, this.pageLoaded);
    }
    // 页面加载
    pageLoaded(payload){
        this.getConfig();
    }
    // 获取配置
    getConfig(){
        getRequest("/admin/config/info", {key: this.state.config_key}, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                const obj = JSON.parse(data.data.value);
                obj.loading = false;
                this.setState(obj, ()=>{
                    this.replyRef.current.initData(this.state.auto_reply);
                });
            }
        })
    }
    // 获取当前配置
    getCurrentConfig(){
        return {
            command: this.commandRef.current.getValue(),
            prompt: this.promptRef.current.getValue(),
            auto_reply: this.replyRef.current.getValue(),
            remind: this.remindRef.current.getValue(),
        }
    }
    // 更新游戏配置
    updateConfig(){
        // 判断是否有改变, 无改变则不更新
        const newConfig = this.getCurrentConfig()
        const jsonStr = JSON.stringify(newConfig);
        postRequest("/admin/config/set", {key: this.state.config_key, value: jsonStr}, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                message.success("配置已更新");
            }
        })
    }
    // 更新指令
    updateAttr(v,attr, attrSub){
        const obj = this.state[attr];
        obj[attrSub] = v;
        const updateObj = {};
        updateObj[attr] = obj;
        this.setState(updateObj);
    }
    render() {
        return (
            <div className="message-setting-panel">
                <Spin tip="加载中...." spinning={this.state.loading}>
                    <div className="btn-group">
                        <Button type="primary" className="btn-success" onClick={this.updateConfig}>保存配置</Button>
                    </div>
                    <Row>
                        <MessageRemind data={this.state.remind} ref={this.remindRef} onUpdate={this.updateAttr}/>
                        <MessageCommand data={this.state.command} ref={this.commandRef} onUpdate={this.updateAttr}/>
                        <MessageAutoReply ref={this.replyRef} />
                        <MessagePrompt data={this.state.prompt} ref={this.promptRef} onUpdate={this.updateAttr}/>
                    </Row>
                </Spin>
            </div>
        );
    }
}

export default SettingMessage;

