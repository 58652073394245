import React, {Component} from 'react';
import './style.less';
import {emitter} from "../../../../common/util/event";
import {MenuKey, rangePresets} from "../../../../common/constant/common";
import {getRequest} from "../../../../common/util/request";
import {Button, DatePicker, Input, message, Pagination, Table} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {formatYuan} from "../../../../common/util/number";
import UserInfo from "../../../../common/component/userInfo";

class UserWinlose extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: '',
            start_at: null,
            end_at: null,
            page: 1,
            limit: 15,
            list: [],
            total: 0,
            amount: 0,
        };
        this.pageLoaded = this.pageLoaded.bind(this);
        this.changePage = this.changePage.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
    }
    componentDidMount() {
        emitter.addListener(MenuKey.UserWinLose.id, this.pageLoaded);
    }
    componentWillUnmount() {
        emitter.removeListener(MenuKey.UserWinLose.id, this.pageLoaded);
    }
    // 页面加载
    pageLoaded(payload){
        this.setState({user_id: payload.user_id}, ()=>{
            this.getUserWinLose();
        });
    }
    // 获取列表
    getUserWinLose(){
        const query = {
            user_id: this.state.user_id,
            start_at: this.state.start_at !== null ? this.state.start_at.format('YYYY-MM-DD') : "",
            end_at: this.state.end_at !== null ? this.state.end_at.format('YYYY-MM-DD') : "",
            page: this.state.page,
            page_size: this.state.limit,
        };
        getRequest("/admin/user/winlose", query, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                this.setState({list: data.data.list, total: data.data.total, amount: data.data.amount});
            }
        })
    }
    // 更换页码
    changePage(page, pageSize){
        this.setState({limit: pageSize, page: page}, ()=>{
            this.getUserWinLose();
        });
    }
    // 更改日期
    onChangeDate(value){
        if(value === null){
            this.setState({start_at: null, end_at: null});
        }else{
            this.setState({start_at: value[0], end_at: value[1]});
        }
    }
    render() {
        const columns = [
            {
                title: '用户',
                dataIndex: 'user',
                key: 'user',
                render: (text,d)=>{
                    return (
                        <UserInfo data={text}/>
                    )
                }
            },
            {
                title: '时间',
                dataIndex: 'date',
                key: 'date',
            },
            {
                title: '下注期数',
                dataIndex: 'bet_count',
                key: 'bet_count',
            },
            {
                title: '当日流水(元)',
                dataIndex: 'wage',
                key: 'wage',
                render: (text,d)=>{
                    return formatYuan(text)
                }
            },
            {
                title: '当日流水返利(元)',
                dataIndex: 'wage_rebate',
                key: 'wage_rebate',
                render: (text,d)=>{
                    return formatYuan(text)
                }
            },
            {
                title: '当日输赢(元)',
                dataIndex: 'points',
                key: 'points',
                render: (text,d)=>{
                    return <span className={this.state.winlose > 0 ? "number-lose" : this.state.winlose === 0 ? "" : "number-win"}>{formatYuan(text)}</span>
                }
            },
        ];
        const summary = (pageData) => {
            return (
                <>
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={6}>总计: <span className={this.state.amount > 0 ? "number-lose" : this.state.amount === 0 ? "" : "number-win"}>{formatYuan(this.state.amount)}</span> / 共{this.state.total}天</Table.Summary.Cell>
                    </Table.Summary.Row>
                </>
            );
        }
        return (
            <div className="user-winlose-panel">
                <div className="filter-block">
                    <label>用户: </label>
                    <Input className="input-user" placeholder="用户ID" value={this.state.user_id} disabled={true}/>
                    <label className="label-time">时间: </label>
                    <DatePicker.RangePicker presets={rangePresets} value={[this.state.start_at,this.state.end_at]} onChange={this.onChangeDate}/>
                    <Button type="primary" icon={<SearchOutlined />} onClick={()=>this.changePage(1,this.state.limit)}>查询</Button>
                </div>
                <Table bordered={true} rowKey="id" size="small" dataSource={this.state.list} columns={columns} pagination={false} className="user-winlose-table" summary={summary}/>
                <Pagination
                    size="small"
                    current={this.state.page}
                    total={this.state.total}
                    pageSize={this.state.limit}
                    onChange={this.changePage}
                    showSizeChanger={false}
                    showTotal={(total) => `共 ${total} 条数据`}
                />
            </div>
        );
    }
}

export default UserWinlose;

