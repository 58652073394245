import {BankOutlined, RedditOutlined, GiftOutlined, MessageOutlined, TagsOutlined, RobotOutlined, EyeOutlined, TableOutlined, SettingOutlined, UserOutlined, UsergroupAddOutlined, PayCircleOutlined, AccountBookOutlined,ChromeOutlined} from "@ant-design/icons";
import GameCurrent from "../../main/home/content/gameCurrent";
import GameResultList from "../../main/home/content/gameResultList";
import UserList from "../../main/home/content/userList";
import GameBetRecord from "../../main/home/content/gameBetRecord";
import GroupList from "../../main/home/content/groupList";
import GameWinLose from "../../main/home/content/gameWinLose/gameWinlose";
import UserInfo from "../../main/home/content/userInfo";
import UserWinlose from "../../main/home/content/userWinlose";
import UserPointsRecord from "../../main/home/content/userPointsRecord";
import SettingActivity from "../../main/home/content/settingActivty";
import SettingGame from "../../main/home/content/settingGame";
import BotList from "../../main/home/content/botList";
import SettingBetModel from "../../main/home/content/settingBetModel";
import SettingMessage from "../../main/home/content/settingMesasge";
import ActivityWageRebate from "../../main/home/content/activityWageRebate";
import dayjs from "dayjs";
import SettingAdvertisement from "../../main/home/content/settingAdvertisement";
import PaymentList from "../../main/home/content/paymentList";
import CenterBank from "../../main/home/content/centerbank";

// token过期错误
export const TokenExpireErrCode = 10102;

// 菜单
export const MenuKey = {
    Home: {id: "home", name: "游戏监控", icon: <EyeOutlined />, component: <GameCurrent />},
    UserInfo: {id: "user_info", name: "玩家详情", icon: null, component: <UserInfo />},
    UserList: {id: "user_list", name: "玩家列表", icon: <UserOutlined />, component: <UserList />},
    BotList: {id: "bot_list", name: "机器人列表", icon: <RobotOutlined />, component: <BotList />},
    GroupList: {id: "group_list", name: "群列表", icon: <UsergroupAddOutlined />, component: <GroupList />},
    UserWinLose: {id: "user_win_lose", name: "玩家输赢", icon: null, component: <UserWinlose />},
    UserPointsRecord: {id: "user_points_record", name: "积分变化", icon: null, component: <UserPointsRecord />},
    PaymentList: {id: "payment_list", name: "充提列表", icon: <PayCircleOutlined />, component: <PaymentList />},
    CenterBank: {id: "center_bank", name: "中央银行", icon: <BankOutlined />, component: <CenterBank />},
    GameResultList: {id: "game_result", name: "游戏开奖", icon: <TableOutlined />, component: <GameResultList />},
    GameBetRecord: {id: "game_bet_record", name: "游戏下注", icon: null, component: <GameBetRecord />},
    GameWinLose: {id: "game_win_lose", name: "游戏输赢", icon: <AccountBookOutlined />, component: <GameWinLose />},
    ActivityWageRebate: {id: "activity_wage_rebate", name: "流水返利", icon: <GiftOutlined />, component: <ActivityWageRebate />},
    SettingGame: {id: "setting_game", name: "游戏配置", icon: <SettingOutlined />, component: <SettingGame />},
    SettingActivity: {id: "setting_activity", name: "活动配置", icon: <ChromeOutlined />, component: <SettingActivity />},
    SettingMessage: {id: "setting_message", name: "消息配置", icon: <MessageOutlined />, component: <SettingMessage />},
    SettingBetModel: {id: "setting_bet_model", name: "机器人下注模式", icon: <TagsOutlined />, component: <SettingBetModel />},
    SettingAdvertisement: {id: "setting_advertisement", name: "广告设置", icon: <RedditOutlined />, component: <SettingAdvertisement />},
}

export const rangePresets = [
    { label: '今天', value: [dayjs(), dayjs()] },
    { label: '昨天', value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')] },
    { label: '本周', value: [dayjs().startOf('week'), dayjs()] },
    { label: '上周', value: [dayjs().add(-1, 'week').startOf('week'), dayjs().add(-1, 'week').endOf('week')] },
    { label: '本月', value: [dayjs().startOf('month'), dayjs()] },
    { label: '上月', value: [dayjs().add(-1, 'month').startOf('month'), dayjs().add(-1, 'month').endOf('month')] },
];

export const rangeMonthPresets = [
    { label: '本月', value: [dayjs().startOf('month'), dayjs()] },
    { label: '上月', value: [dayjs().add(-1, 'month').startOf('month'), dayjs().add(-1, 'month').endOf('month')] },
];

export const datePresets = [
    { label: '今天', value: dayjs() },
    { label: '昨天', value: dayjs().add(-1, 'd') },
    { label: '前天', value: dayjs().add(-2, 'd') },
];