// 校验手机号
export function checkPhone(phone){
    if(!(/^1[3456789]\d{9}$/.test(phone))){
        return false;
    }
    return true;
}

// 校验密码
export function checkPassword(password){
    if(!(/^\w{6,16}$/.test(password))){
        return false;
    }
    return true;
}

// 校验昵称
export function checkNickname(nickname){
    if(!(/^[\u4e00-\u9fa5A-Za-z0-9-_]*$/.test(nickname))){
        return false;
    }
    return true;
}

// 校验邮箱
export function checkEmail(email){
    if(!(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/.test(email))){
        return false;
    }
    return true;
}
