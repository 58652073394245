import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './main/app.js';
import reportWebVitals from './reportWebVitals';
import zhCN from 'antd/locale/zh_CN';
import {ConfigProvider} from 'antd';
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ConfigProvider locale={zhCN}>
        <App />
    </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
