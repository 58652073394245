import React,{Component} from 'react';
import {Button, Input, InputNumber, message, Modal} from "antd";
import {postRequest} from "../../../../common/util/request";
import {getUniqueID} from "../../../../common/util/number";

class AddAdModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.data.id ? this.props.data.id : getUniqueID(),
            duration: this.props.data.duration ? this.props.data.duration : 60,
            content: this.props.data.content ? this.props.data.content : '',
        };
        this.add = this.add.bind(this);
    }
    componentDidMount() {
    }
    add(){
        if(this.state.content === ""){
            message.warning("请输入发送内容");
            return;
        }
        const obj = {
            id: this.state.id,
            duration: this.state.duration,
            content: this.state.content,
        };
        this.props.onOk(obj);
    }
    render() {
        return (
            <Modal
                className="add-ad-model-modal"
                open={true}
                onCancel={this.props.onCancel}
                title={this.props.data.content === undefined ? '添加广告' : '编辑广告'}
                maskClosable={false}
                onOk={this.add}
            >
                <div className="form-div">
                    <label>间隔周期(秒):</label>
                    <InputNumber value={this.state.duration} min={60} controls={false} onChange={(v)=>this.setState({duration: v})}/>
                </div>
                <div className="form-div">
                    <label>发送内容:</label>
                    <Input.TextArea value={this.state.content} autoSize={{minRows: 3, maxRows: 3}} onChange={(e)=>this.setState({content: e.target.value})}/>
                </div>
            </Modal>
        );
    }
}

export default AddAdModal;

