import {Component} from 'react';
import { withRouter } from "react-router-dom";
import {Menu} from 'antd';
import './style.less';
import {MenuKey} from "../../../common/constant/common";
import {emitter, EVENTS} from "../../../common/util/event";

class CustomMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {
    }
    componentWillUnmount(){
    }
    // 显示菜单
    showMenu(k){
        emitter.emit(EVENTS.SHOW_MENU, k);
    }
    // 获取菜单
    getMenu(menuObj){
        return {
            label: menuObj.name,
            key: menuObj.id,
            icon: menuObj.icon,
            onClick: ()=>this.showMenu(menuObj.id)
        }
    }
    render() {
        // 菜单
        const items = [
            this.getMenu(MenuKey.Home),
            {
                label: "用户管理",
                key: "users",
                type: "group",
                children: [
                    this.getMenu(MenuKey.BotList),
                    this.getMenu(MenuKey.GroupList),
                    this.getMenu(MenuKey.UserList),
                    this.getMenu(MenuKey.PaymentList),
                ]
            },
            {
                label: "游戏管理",
                key: "statistics",
                type: "group",
                children: [
                    this.getMenu(MenuKey.CenterBank),
                    this.getMenu(MenuKey.GameResultList),
                    this.getMenu(MenuKey.GameWinLose),
                    this.getMenu(MenuKey.ActivityWageRebate),
                ]
            },
            {
                label: "系统配置",
                key: "system_config",
                type: "group",
                children: [
                    this.getMenu(MenuKey.SettingGame),
                    // this.getMenu(MenuKey.SettingActivity),
                    this.getMenu(MenuKey.SettingMessage),
                    this.getMenu(MenuKey.SettingBetModel),
                    this.getMenu(MenuKey.SettingAdvertisement),
                ]
            }
        ];
        return (
            <Menu
                className="custom-menu"
                selectedKeys={[]}
                mode="inline"
                items={items}
            />
        );
    }
}

export default withRouter(CustomMenu);

