import React, {Component} from 'react';
import {Button, Card, message, Table, Tag} from "antd";
import UserInfo from "../../../../common/component/userInfo";
import {emitter, EVENTS} from "../../../../common/util/event";
import {getRequest, postRequest} from "../../../../common/util/request";

class PaymentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            latest: 0, // 最新记录id
        };
        this.getList = this.getList.bind(this);
        this.audit = this.audit.bind(this);
    }
    componentDidMount() {
        this.getList();
        // 定时刷新回合状态
        this.timer = setInterval(this.getList, 5000);
    }
    componentWillUnmount() {
        if(this.timer !== null){
            clearInterval(this.timer);
            this.timer = null;
        }
    }
    // 获取数据
    getList(){
        const query = {
            type: 0,
            user_id: '',
            status: 0,
            start_at: "",
            end_at: "",
            page: 1,
            page_size: 100,
        };
        getRequest("/admin/payment/list", query, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                this.setState({list: data.data.list.reverse()}, ()=>{
                    // 播放提醒
                    if(data.data.list.length > 0 && data.data.list[0].id > this.state.latest){
                        this.setState({latest: data.data.list[0].id},()=>{
                            if(data.data.list[0].type === 1){
                                emitter.emit(EVENTS.RING_BELL, 1);
                            }else{
                                emitter.emit(EVENTS.RING_BELL, 2);
                            }
                        });
                    }
                });
            }
        })
    }
    // 审核
    audit(id, flag){
        postRequest("/admin/payment/audit", {id: id, state: flag}, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                message.success("审核成功");
                this.getList();
            }
        });
    }
    render() {
        const columns = [
            {
                title: '用户',
                dataIndex: 'user',
                key: 'user',
                render: (text,d)=>{
                    return (
                        <UserInfo data={text} />
                    )
                }
            },
            {
                title: '充提金额',
                dataIndex: 'amount',
                key: 'amount',
                render: (text,d)=>{
                    if(d.type === 1){
                        return <span className="number-win">查: {text.toLocaleString()}</span>
                    }else{
                        return <span className="number-lose">回: {text.toLocaleString()}</span>
                    }
                }
            },
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                render: (text,d)=>{
                    return (
                        <div className="btn-group">
                            <Button size="small" className="btn-success" onClick={()=>this.audit(d.id, true)}>通过</Button><br/>
                            <Button size="small" className="btn-warning" onClick={()=>this.audit(d.id, false)}>拒绝</Button>
                        </div>
                    )
                }
            },
        ];
        return (
            <Card className="payment-list-card">
                <Tag className="tag-label" color="#ffc107">查回列表</Tag>
                <Table
                    bordered={true}
                    dataSource={this.state.list}
                    rowKey="id"
                    columns={columns}
                    size="small"
                    pagination={false}
                />
            </Card>
        );
    }
}

export default PaymentList;

