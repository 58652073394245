import React, {Component} from 'react';
import './style.less';
import {Button, DatePicker, Input, message, Pagination, Select, Table} from "antd";
import {formatYuan} from "../../../../common/util/number";
import {parseBetString} from "../../../../common/constant/game28";
import {getRequest} from "../../../../common/util/request";
import {emitter} from "../../../../common/util/event";
import {MenuKey, rangePresets} from "../../../../common/constant/common";
import {SearchOutlined} from "@ant-design/icons";
import UserInfo from "../../../../common/component/userInfo";

class GameBetRecord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            game_id: 1,
            page: 1,
            limit: 50,
            list: [],
            total: 0,
            bet_amount: 0,
            winlose: 0,
            game_no: '',
            user_id: '',
            start_at: null,
            end_at: null,
        };
        this.changePage = this.changePage.bind(this);
        this.pageLoaded = this.pageLoaded.bind(this);
        this.getBetRecord = this.getBetRecord.bind(this);
        this.search = this.search.bind(this);
        this.changeGameNo = this.changeGameNo.bind(this);
        this.changeGameUser = this.changeGameUser.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.changeGame = this.changeGame.bind(this);
    }
    componentDidMount() {
        emitter.addListener(MenuKey.GameBetRecord.id, this.pageLoaded);
    }
    componentWillUnmount() {
        emitter.removeListener(MenuKey.GameBetRecord.id, this.pageLoaded);
    }
    // 页面加载
    pageLoaded(payload){
        this.setState({game_id: payload.game_id?payload.game_id:1,game_no: payload.game_no?payload.game_no.toString():'', user_id: payload.user_id?payload.user_id:''}, ()=>{
            this.getBetRecord();
        })
    }
    // 获取投注记录
    getBetRecord(){
        const query = {
            game_id: this.state.game_id,
            page: this.state.page,
            page_size: this.state.limit,
            game_no: this.state.game_no !== '' ? parseInt(this.state.game_no) : 0,
            user_id: this.state.user_id,
            start_at: this.state.start_at !== null ? this.state.start_at.format('YYYY-MM-DD') : "",
            end_at: this.state.end_at !== null ? this.state.end_at.format('YYYY-MM-DD') : "",
        }
        getRequest("/admin/game/bet/record", query, (data)=>{
            if(data.code !== 0){
                message.error(data.msg);
            }else{
                this.setState({list: data.data.list, total: data.data.total, bet_amount: data.data.bet_amount, winlose: data.data.winlose});
            }
        })
    }
    // 更换页码
    changePage(page, pageSize){
        this.setState({limit: pageSize, page: page}, ()=>{
            this.getBetRecord();
        });
    }
    // 更改日期
    onChangeDate(value){
        if(value === null){
            this.setState({start_at: null, end_at: null});
        }else{
            this.setState({start_at: value[0], end_at: value[1]});
        }
    }
    // 获取用户投注详情
    getUserBetDetail(d){
        // 获取下注数组
        const betArr = parseBetString(d.bet_info);
        // 获取中奖数组
        const lotteryArr = parseBetString(d.lottery_info);
        // 遍历处理
        const res = [];
        for(let i=0;i<betArr.length;i++){
            let winAmt = 0;
            for(let j=0;j<lotteryArr.length;j++){
                if(lotteryArr[j].id === betArr[i].id){
                    winAmt = lotteryArr[j].value;
                    break;
                }
            }
            const obj = {
                bet_num: betArr[i].id,
                bet_amount: betArr[i].value,
                win_amount: winAmt,
            };
            res.push(obj);
        }
        return res;
    }
    // 搜索
    search(){
        this.setState({page: 1}, ()=>{
            this.getBetRecord();
        })
    }
    // 修改期号
    changeGameNo(e){
        const v = e.target.value;
        this.setState({game_no: v});
    }
    // 修改用户
    changeGameUser(e){
        const v = e.target.value;
        this.setState({user_id: v});
    }
    // 修改游戏
    changeGame(v){
        this.setState({game_id: v});
    }
    render() {
        const columns = [
            // {
            //     title: '用户',
            //     dataIndex: 'user',
            //     key: 'user',
            //     render: (text,d)=>{
            //         return (
            //             <UserInfo data={text} />
            //         )
            //     }
            // },
            // {
            //     title: '开奖期号',
            //     dataIndex: 'game_no',
            //     key: 'game_no',
            //     render: (text,d)=>{
            //         return (
            //             <div className="game-no-info">
            //                 <p className="time">{d.game_draw_at}</p>
            //                 <p className="number">第{d.game_no}期</p>
            //             </div>
            //         )
            //     }
            // },
            // {
            //     title: '开奖结果',
            //     dataIndex: 'open_numbers',
            //     key: 'open_numbers',
            //     render: (text,d)=>{
            //         return (
            //             <span>
            //                     <span className="number-ball">{text[0]}</span>+
            //                     <span className="number-ball">{text[1]}</span>+
            //                     <span className="number-ball">{text[2]}</span>=
            //                     <span className="number-ball">{tc(text[3])}</span>
            //                 </span>
            //         )
            //     }
            // },
            // {
            //     title: '投注/输赢(元)',
            //     dataIndex: 'bet_amount',
            //     key: 'bet_amount',
            //     render: (text,d)=>{
            //         const amount = d.winlose-d.bet_amount;
            //         return (
            //             <div className="bet-info">
            //                 <p className="time">{d.created_at}</p>
            //                 <p>下注: {formatYuan(d.bet_amount)}</p>
            //                 <p>输赢: <span className={amount > 0 ? "number-win" : amount === 0 ? "" : "number-lose"}>{formatYuan(amount)}</span></p>
            //             </div>
            //         )
            //     }
            // },
            // {
            //     title: '投注明细',
            //     dataIndex: 'id',
            //     key: 'id',
            //     render: (text,d)=>{
            //         const arr = this.getUserBetDetail(d);
            //         return (
            //             <table className="user-bet-table">
            //                 <tbody>
            //                     <tr>
            //                         <td>选项</td>
            //                         {
            //                             arr.map((item, i)=>{
            //                                 return (
            //                                     <td key={i} className={item.win_amount>0 ? "number-win":""}>{getBetNumName(item.bet_num)}</td>
            //                                 )
            //                             })
            //                         }
            //                     </tr>
            //                     <tr>
            //                         <td>下注</td>
            //                         {
            //                             arr.map((item, i)=>{
            //                                 return (
            //                                     <td key={i} className={item.win_amount>0 ? "number-win":""}>{formatYuan(item.bet_amount)}</td>
            //                                 )
            //                             })
            //                         }
            //                     </tr>
            //                     <tr>
            //                         <td>中奖</td>
            //                         {
            //                             arr.map((item, i)=>{
            //                                 return (
            //                                     <td key={i} className={item.win_amount>0 ? "number-win":""}>{formatYuan(item.win_amount)}</td>
            //                                 )
            //                             })
            //                         }
            //                     </tr>
            //                     <tr>
            //                         <td>赔率</td>
            //                         {
            //                             arr.map((item, i)=>{
            //                                 return (
            //                                     <td key={i} className={item.win_amount>0 ? "number-win":""}>{parseFloat((item.win_amount/item.bet_amount).toFixed(2))}</td>
            //                                 )
            //                             })
            //                         }
            //                     </tr>
            //                 </tbody>
            //             </table>
            //         )
            //     }
            // },
            {
                title: '用户',
                dataIndex: 'user',
                key: 'user',
                render: (text,d)=>{
                    return (
                        <UserInfo data={text} />
                    )
                }
            },
            {
                title: '开奖期号',
                dataIndex: 'game_no',
                key: 'game_no',
            },
            {
                title: '开奖结果',
                dataIndex: 'open_numbers',
                key: 'open_numbers',
                render: (text,d)=>{
                    return `${text[0]}+${text[1]}+${text[2]}=${text[3]}`
                }
            },
            {
                title: '开奖时间',
                dataIndex: 'game_draw_at',
                key: 'game_draw_at',
            },
            {
                title: '下注时间',
                dataIndex: 'created_at',
                key: 'created_at',
            },
            {
                title: '下注金额',
                dataIndex: 'bet_amount',
                key: 'bet_amount',
                render: (text,d)=>{
                    return formatYuan(d.bet_amount)
                }
            },
            {
                title: '输赢',
                dataIndex: 'winlose',
                key: 'winlose',
                render: (text,d)=>{
                    const amount = d.winlose-d.bet_amount;
                    return <span className={amount > 0 ? "number-lose" : amount === 0 ? "" : "number-win"}>{formatYuan(amount)}</span>
                }
            },
            {
                title: '投注明细',
                dataIndex: 'bet_str',
                key: 'bet_str',
            },
        ];
        const summary = (pageData) => {
            const winAmt = this.state.winlose-this.state.bet_amount;
            return (
                <>
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={8}>总计下注: {formatYuan(this.state.bet_amount)}, 输赢: <span className={winAmt > 0 ? "number-lose" : winAmt === 0 ? "" : "number-win"}>{formatYuan(winAmt)}</span> / 共{this.state.total}条</Table.Summary.Cell>
                    </Table.Summary.Row>
                </>
            );
        }
        return (
            <div className="game-bet-record-panel">
                <div className="filter-block">
                    <Select className="input-select-type" value={this.state.game_id} onChange={this.changeGame}>
                        <Select.Option value={1}>加拿大28</Select.Option>
                        <Select.Option value={2}>台湾28</Select.Option>
                    </Select>
                    {/*<label>期号: </label>*/}
                    <Input className="input-game-no" placeholder="游戏期号" value={this.state.game_no} onChange={this.changeGameNo}/>
                    <label className="label-user">用户: </label>
                    <Input className="input-user" placeholder="用户ID" value={this.state.user_id} onChange={this.changeGameUser}/>
                    <label className="label-user">时间: </label>
                    <DatePicker.RangePicker presets={rangePresets} value={[this.state.start_at,this.state.end_at]} onChange={this.onChangeDate}/>
                    <Button type="primary" icon={<SearchOutlined />} onClick={this.search}>查询</Button>
                </div>
                <Table summary={summary} bordered={true} rowKey="id" size="small" dataSource={this.state.list} columns={columns} pagination={false} className="bet-record-table"/>
                <Pagination
                    size="small"
                    current={this.state.page}
                    total={this.state.total}
                    pageSize={this.state.limit}
                    onChange={this.changePage}
                    showSizeChanger={false}
                />
            </div>
        );
    }
}

export default GameBetRecord;

